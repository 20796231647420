import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  APIService,
  Tanque,
  GetTanqueQuery,
  ListTanquesQuery,
  ModelTanqueFilterInput,
  ModelSubscriptionIDInput,
  ModelSubscriptionTanqueFilterInput,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { appConstants } from '../../shared/constants/constants';
import { filterInputs } from '../../shared/types/filter-inputs';

@Injectable({
  providedIn: 'root',
})
export class TanquesService {
  selectedTanqueChanged = new Subject<Tanque>();
  tanquesChanged = new Subject<Tanque[]>();
  decoupledTanquesChanged = new Subject<Tanque[]>();

  private readonly initialTanque: Tanque;
  private selectedTanque: Tanque;
  private tanques: Tanque[] = [];
  private decoupledTanques: Tanque[] = [];
  private tanquesFilter: ModelTanqueFilterInput = {};
  private tanquesFilterForSubscriptions: ModelSubscriptionTanqueFilterInput =
    {};
  private decoupledTanquesFilter: ModelTanqueFilterInput = {};
  private decoupledTanquesFilterForSubscriptions: ModelSubscriptionTanqueFilterInput =
    {};
  private selectedTanqueFilterForSubscriptions: ModelSubscriptionTanqueFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService
  ) {
    this.initialTanque = {
      ...appConstants.tanque.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedTanque = this.initialTanque;
    this.setTanquesFilter();
  }

  // ---------------------------------------
  // Métodos para el tanque default
  // ---------------------------------------
  /**
   * Retorna una estructura de tanque vacía para iniciar
   * un objeto de tipo Tanque.
   * @return {Tanque}
   */
  getInitialTanque(): Tanque {
    return { ...this.initialTanque };
  }

  // --------------------------------------------
  // Métodos para el tanque seleccionado
  // --------------------------------------------
  /**
   * Configura el tanque seleccionado para referencia de
   * todos los componentes.
   * @param {Tanque} tanque Tanque seleccionado.
   */
  setSelectedTanque(tanque: Tanque): void {
    this.selectedTanque = tanque;
    this.setSelectedTanqueFilterForSubscriptions();
    this.selectedTanqueChanged.next({ ...this.selectedTanque });
    console.log('selectedTanque', this.selectedTanque);

    // Documentos
    this.documentsService.setId('tanqueId', this.selectedTanque.tanqueId);
    this.documentsService.setSelectedModel('TANQUE');
    this.documentsService.setSelectedCenter(tanque.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del tanque actualizados.'));
  }

  /**
   * Actualiza los datos del tanque seleccionado.
   * @return {Promise}
   */
  async refreshSelectedTanque(): Promise<void> {
    const getTanqueResult: GetTanqueQuery = await this.api.GetTanque(
      this.selectedTanque.tanqueId
    );
    this.setSelectedTanque(<Tanque>getTanqueResult);
  }

  /**
   * Retorna el tanque seleccionado.
   * @return {Tanque}
   */
  getSelectedTanque(): Tanque {
    return { ...this.selectedTanque };
  }

  // --------------------------------
  // Métodos para la lista de Tanques
  // --------------------------------
  /**
   * Configura la lista de tanques para referencia de
   * todos los componentes.
   * @param {Tanque[]} tanques Lista de tanques.
   */
  setTanques(tanques: Tanque[]): void {
    this.tanques = tanques;
    this.setTanquesFilter();
    this.tanquesChanged.next(this.tanques.slice());
  }

  /**
   * Actualiza la lista de tanques.
   * @return {Promise}
   */
  async refreshTanques(): Promise<void> {
    let listTanquesResult: ListTanquesQuery = await this.api.ListTanques(
      '',
      this.getTanquesFilter()
    );
    let tempListTanques = <Tanque[]>listTanquesResult.items;
    let nextToken = listTanquesResult.nextToken;
    // Es posible que la primera query no retorne todos los tanques.
    while (nextToken) {
      let loopListTanquesResult: ListTanquesQuery = await this.api.ListTanques(
        '',
        this.getTanquesFilter(),
        100,
        nextToken
      );
      tempListTanques.push(...(<Tanque[]>loopListTanquesResult.items));
      nextToken = loopListTanquesResult.nextToken;
    }

    this.setTanques(tempListTanques.slice());
  }

  /**
   * Retorna la lista de tanques.
   * @return {Tanque[]}
   */
  getTanques(): Tanque[] {
    return this.tanques.slice();
  }

  /**
   * Configura la lista de tanques desacoplados para referencia de
   * todos los componentes.
   * @param {Tanque[]} tanques Lista de tanques acoplados.
   */
  setDecoupledTanques(tanques: Tanque[]): void {
    this.decoupledTanques = tanques;
    this.setTanquesFilter();
    this.decoupledTanquesChanged.next(this.decoupledTanques.slice());
  }

  /**
   * Actualiza la lista de tanques desacoplados.
   * @return {Promise}
   */
  async refreshDecoupledTanques(): Promise<void> {
    let listTanquesResult: ListTanquesQuery = await this.api.ListTanques(
      '',
      this.getDecoupledTanquesFilter()
    );
    let tempListDecoupledTanques = <Tanque[]>listTanquesResult.items;
    let nextToken = listTanquesResult.nextToken;
    // Es posible que la primera query no retorne todos los tanques.
    while (nextToken) {
      let loopListTanquesResult: ListTanquesQuery = await this.api.ListTanques(
        '',
        this.getDecoupledTanquesFilter(),
        100,
        nextToken
      );
      tempListDecoupledTanques.push(...(<Tanque[]>loopListTanquesResult.items));
      nextToken = loopListTanquesResult.nextToken;
    }

    this.setDecoupledTanques(tempListDecoupledTanques.slice());
  }

  /**
   * Retorna la lista de tanques desacoplados.
   * @return {Tanque[]}
   */
  getDecoupledTanques(): Tanque[] {
    return this.decoupledTanques.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de tanques y subscripciones.
   * @private
   */
  private setTanquesFilter(): void {
    const business: string = this.usersService.business.value.toUpperCase();
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();
    const decoupledFixedFilters:
      | ModelTanqueFilterInput
      | ModelSubscriptionTanqueFilterInput = {
      tanqueCoupleCoupleId: { notContains: '#' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      ...(business !== 'FOREIGN' && { sapId: { ne: 'Sin Asignar' } }),
    };

    this.tanquesFilter = <ModelTanqueFilterInput>modelFilterInput;
    this.tanquesFilterForSubscriptions = <ModelSubscriptionTanqueFilterInput>(
      modelFilterInput
    );
    this.decoupledTanquesFilter = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
    this.decoupledTanquesFilterForSubscriptions = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de tanques.
   * @return {ModelTanqueFilterInput}
   */
  getTanquesFilter(): ModelTanqueFilterInput {
    return { ...this.tanquesFilter };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de tanques.
   * @return {ModelSubscriptionTanqueFilterInput}
   */
  getTanquesFilterForSubscriptions(): ModelSubscriptionTanqueFilterInput {
    return { ...this.tanquesFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del tanque seleccionado.
   */
  private setSelectedTanqueFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedTanque.tanqueId,
    };
    this.selectedTanqueFilterForSubscriptions = {
      tanqueId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del tanque seleccionado.
   * @return {ModelSubscriptionTanqueFilterInput}
   */
  getSelectedTanqueFilterForSubscriptions(): ModelSubscriptionTanqueFilterInput {
    return { ...this.selectedTanqueFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de tanques desacoplados.
   * @return {ModelSubscriptionTanqueFilterInput}
   */
  getDecoupledTanquesFilterForSubscriptions(): ModelSubscriptionTanqueFilterInput {
    return { ...this.decoupledTanquesFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para subscripciones
   * de listas de tanques desacoplados.
   * @return {ModelTanqueFilterInput}
   */
  getDecoupledTanquesFilter(): ModelTanqueFilterInput {
    return { ...this.decoupledTanquesFilter };
  }
}
