import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Feedback } from '../interfaces/feedback';
import { FeedbackClasses } from '../interfaces/feedback-classes';

@Injectable({
  providedIn: 'root',
})
export class FeedbacksService {
  feedbacks: Feedback[] = [];
  feedbackClasses: FeedbackClasses = {
    info: 'bg-info text-light',
    success: 'bg-success text-light',
    danger: 'bg-danger text-light',
  };

  /**
   * Agrega una notificación a la lista de notificaciones.
   * @param {string} message Mensaje de la notificación.
   * @param {string} type Tipo de notificación.
   */
  showFeedback(message: string, type: keyof FeedbackClasses): void {
    this.feedbacks.push({
      message: message,
      classname: this.feedbackClasses[type] || 'bg-info text-light',
      delay: environment.feedbackDelay,
    });
  }

  /**
   * Elimina una notificación de la lista de notificaciones.
   * @param {Feedback} feedback Notificación.
   */
  removeFeedback(feedback: Feedback): void {
    this.feedbacks = this.feedbacks.filter(
      (f: Feedback): boolean => f !== feedback
    );
  }

  /**
   * Limpia la lista de notificaciones.
   */
  clearFeedbacks(): void {
    this.feedbacks.splice(0, this.feedbacks.length);
  }

  /**
   * Muestra un feedback de error por cada error recibido
   * en la respuesta de un catch.
   * @param {any} response Respuesta recibida en un catch.
   * @param {string} message Mensaje que se desea mostrar.
   */
  showErrorFeedbacks(response: any, message: string): void {
    for (let error of response.errors) {
      this.showFeedback(message + ': ' + error.message, 'danger');
    }
  }
}
