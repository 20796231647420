import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { RtcEvaluationsService } from '../rtc-evaluations.service';
import { SelectedOptions } from '../../../shared/interfaces/type-options';
import { EvaluatedRozForm, Document } from '../../../app-sync.service';
import { appConstants } from '../../../shared/constants/constants';
import { DocumentsService } from '../../documents/documents.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-rtc-historial',
  templateUrl: './rtc-historial.component.html',
  styleUrls: ['./rtc-historial.component.css'],
})
export class RtcHistorialComponent implements OnInit, OnDestroy {
  @ViewChild('documentViewer', { static: false }) documentViewer:
    | TemplateRef<any>
    | undefined;
  entityName: string = 'Evaluaciones';
  selectedOptions: SelectedOptions =
    this.rtcEvaluationsService.selectedOptions.value;
  showTable: boolean = false;
  isGettingURL: boolean = false;
  dtOptions: DataTables.Settings = {};
  isGettingEvaluations: boolean = false;
  evaluations: EvaluatedRozForm[];
  evaluationsAttributes: string[] = [
    'Fecha Evaluación',
    'Puntuación',
    'Estado',
    'Evaluador',
    'Acciones',
  ];
  modalTitle: string = '';
  pdfSource: string = '';

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private optionsSelectedSubscription: Subscription = new Subscription();
  private evaluationsChangedSubscription: Subscription = new Subscription();

  constructor(
    private rtcEvaluationsService: RtcEvaluationsService,
    private documentsService: DocumentsService,
    private modalsService: ModalsService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.evaluations = this.rtcEvaluationsService.getEvaluatedRozForms();
  }

  async ngOnInit(): Promise<void> {
    this.selectedOptions = this.rtcEvaluationsService.selectedOptions.value;

    // Si hay opciones seleccionadas cargamos el historial
    if (
      this.rtcEvaluationsService.selectedOptionsDefined &&
      this.rtcEvaluationsService.selectedEntityExists
    ) {
      await this.updateEvaluations();
    }

    // Subscripción a opciones actualizadas.
    this.optionsSelectedSubscription =
      this.rtcEvaluationsService.selectedOptions.subscribe(
        async (selectedOptions: SelectedOptions): Promise<void> => {
          this.selectedOptions = selectedOptions;
          if (
            this.rtcEvaluationsService.selectedOptionsDefined &&
            this.rtcEvaluationsService.selectedEntityExists
          ) {
            // Si el vehículo existe cargamos la lista de evaluaciones.
            await this.updateEvaluations();
          } else if (!this.rtcEvaluationsService.selectedEntityExists) {
            // Si el vehículo no existe, borramos la lista de evaluaciones cargadas previamente.
            this.showTable = false;
            this.evaluations = [];
          }
        }
      );
    // Subscripción a evaluaciones actualizadas.
    this.evaluationsChangedSubscription =
      this.rtcEvaluationsService.evaluationsChanged.subscribe(
        (evaluations: EvaluatedRozForm[]): void => {
          this.evaluations = evaluations;
        }
      );
  }

  /**
   * Refresca la lista de evaluaciones.
   * @private
   * @return {Promise<void>}
   */
  private async updateEvaluations(): Promise<void> {
    this.isGettingEvaluations = true;
    // El setTimeout es un workaround para evitar el error: https://v17.angular.io/errors/NG0100
    setTimeout((): void => {
      this.rtcEvaluationsService.disableLoadButton.next(true);
    }, 0);
    await this.rtcEvaluationsService
      .refreshEvaluatedRozForms()
      .then((): void => {
        this.showTable = true;
        this.isGettingEvaluations = false;
        // El setTimeout es un workaround para evitar el error: https://v17.angular.io/errors/NG0100
        setTimeout((): void => {
          this.rtcEvaluationsService.disableLoadButton.next(false);
        }, 0);
      });
  }

  /**
   * Llama al método que refresca la lista de evaluaciones.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateEvaluations();
  }

  /**
   * Llama al servicio de descarga de documentos.
   * @param {EvaluatedRozForm} evaluation  a descargar.
   * @return {Promise}
   */
  async onDownloadDocument(evaluation: EvaluatedRozForm): Promise<void> {
    let document: Document = appConstants.document.initialization;

    // Agregamos la fecha al nombre en formato DDMMYYYYHHMMSS
    const valueDate: Date = new Date(evaluation.updatedAt);
    const dateArray: string[] = valueDate.toLocaleDateString().split('/');
    let formattedDate: string = dateArray[1].padStart(2, '0');
    formattedDate += dateArray[0].padStart(2, '0');
    formattedDate += dateArray[2];
    formattedDate += valueDate.toTimeString().split(' ')[0].replaceAll(':', '');

    document.name = `Evaluación RTC - ${formattedDate}`;
    document.s3Path = evaluation.s3Path;
    switch (this.selectedOptions.type) {
      case 'VEHICLE':
        document.vehicleDocumentsVehicleId = this.selectedOptions.licensePlate;
        break;
      case 'TRAILER':
        document.trailerDocumentsTrailerId = this.selectedOptions.licensePlate;
        break;
      case 'ENVASADO':
        document.envasadoDocumentsEnvasadoId =
          this.selectedOptions.licensePlate;
        break;
      case 'CISTERNA':
        document.cisternaDocumentsCisternaId =
          this.selectedOptions.licensePlate;
        break;
      case 'TRACTO':
        document.tractoDocumentsTractoId = this.selectedOptions.licensePlate;
        break;
      case 'TANQUE':
        document.tanqueDocumentsTanqueId = this.selectedOptions.licensePlate;
        break;
      case 'SEMIRREMOLQUE':
        document.semirremolqueDocumentsSemirremolqueId =
          this.selectedOptions.licensePlate;
        break;
      default:
        console.log(
          `${this.selectedOptions.type} no encontrado entre las opciones.`
        );
        break;
    }
    await this.documentsService.downloadDocument(document);
  }

  /**
   * Abre el modal de visualización de un documento.
   * @param {EvaluatedRozForm} evaluation Documento a visualizar.
   * @return {Promise}
   */
  async onViewDocument(evaluation: EvaluatedRozForm): Promise<void> {
    this.isGettingURL = true;
    let document: Document = appConstants.document.initialization;

    this.modalTitle = 'Evaluación RTC';
    document.name = this.modalTitle;
    document.s3Path = evaluation.s3Path;

    await this.documentsService
      .getDocumentPreSignedURL(document)
      .then(async (): Promise<void> => {
        this.pdfSource = this.documentsService.documentPreSignedURL;
        await this.modalsService.showModal(
          <TemplateRef<any>>this.documentViewer,
          true
        );
      });
  }

  /**
   * Retorna la fuente de imagen a mostrar en el estado de la evaluación.
   * @param {string} status Estado de la evaluación.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.rtcEvaluationsService.getImageSrc(status);
  }

  /**
   * Acción al tener renderizado un documento a visualizar.
   */
  pageRendered(): void {
    console.log('Documento cargado.');
    this.isGettingURL = false;
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  ngOnDestroy(): void {
    this.optionsSelectedSubscription.unsubscribe();
    this.evaluationsChangedSubscription.unsubscribe();
    console.log('rtc-historial.component subscriptions removed.');
  }

  protected readonly Math = Math;
}
