import { Injectable } from '@angular/core';

import { APIService, ListMastersQuery, Master } from '../../app-sync.service';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';
import { MasterObject } from '../../shared/interfaces/master-object';
import { appConstants } from '../../shared/constants/constants';
import { MasterDisplayMap } from '../../shared/interfaces/master-display-map';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  private master: MasterObject;
  private masterDisplayMap: MasterDisplayMap;
  private masterLoaded: boolean = false;
  private tanquesVolumes: { valueId: string; valueToDisplay: string }[] = [];
  private tanquesContainerNumbers: {
    [index: string]: { valueId: string; valueToDisplay: string }[];
  } = {};
  private cisternasVolumes: { valueId: string; valueToDisplay: string }[] = [];
  private cisternasContainerNumbers: {
    [index: string]: { valueId: string; valueToDisplay: string }[];
  } = {};
  private additionalCentersLoaded: { [key: string]: boolean } = {};

  constructor(
    private api: APIService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService
  ) {
    this.master = {
      BUSINESSES: [appConstants.master.initialization],
      CARRIERS: [appConstants.master.initialization],
      CENTERS: [appConstants.master.initialization],
      'DOCUMENTS#STATUSES': [appConstants.master.initialization],
      'DRIVERS#DOCUMENT_TYPES': [appConstants.master.initialization],
      'DRIVERS#PERMISSION_NUMBER': [appConstants.master.initialization],
      'DRIVERS#STATUSES': [appConstants.master.initialization],
      'TRAILERS#DOCUMENT_TYPES': [appConstants.master.initialization],
      'USERS#STATUSES': [appConstants.master.initialization],
      'VEHICLES#BRANDS': [appConstants.master.initialization],
      'VEHICLES#CAPACITIES': [appConstants.master.initialization],
      'VEHICLES#COLORS': [appConstants.master.initialization],
      'VEHICLES#DOCUMENT_TYPES': [appConstants.master.initialization],
      'VEHICLES#ROUTES': [appConstants.master.initialization],
      'VEHICLES#STATUSES': [appConstants.master.initialization],
      'VEHICLES#TRANSPORT_PLANNING_POINT': [appConstants.master.initialization],
      'VEHICLES#TYPES': [appConstants.master.initialization],
      VEHICLES: [appConstants.master.initialization],
    };
    this.masterDisplayMap = {
      BUSINESSES: {},
      CARRIERS: {},
      CENTERS: {},
      'DOCUMENTS#STATUSES': {},
      'DRIVERS#DOCUMENT_TYPES': {},
      'DRIVERS#PERMISSION_NUMBER': {},
      'DRIVERS#STATUSES': {},
      'TRAILERS#DOCUMENT_TYPES': {},
      'USERS#STATUSES': {},
      'VEHICLES#BRANDS': {},
      'VEHICLES#CAPACITIES': {},
      'VEHICLES#COLORS': {},
      'VEHICLES#DOCUMENT_TYPES': {},
      'VEHICLES#ROUTES': {},
      'VEHICLES#STATUSES': {},
      'VEHICLES#TRANSPORT_PLANNING_POINT': {},
      'VEHICLES#TYPES': {},
      VEHICLES: {},
    };
  }

  /**
   * Configura el maestro y el mapa que transforma ID en
   * texto legible para el front-end.
   * @return {Promise}
   */
  async setMaster(): Promise<void> {
    let tempMaster: any = {};
    let tempMasterDisplayMap: any = {};
    let masterList: Array<Master | null> = [];
    const business: string = this.usersService.business.value.toUpperCase();

    if (!this.masterLoaded) {
      await this.downloadMaster()
        .then((masterListResult: Master[]): void => {
          masterList = masterListResult;
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            'Error al cargar maestro'
          );
        });

      for (const item of masterList) {
        const key: string = item!.masterId.substring(
          0,
          item!.masterId.lastIndexOf(`#${business}_`)
        );
        tempMaster[key] = tempMaster[key] ? tempMaster[key] : [];
        tempMaster[key].push(item);
        tempMasterDisplayMap[key] = tempMasterDisplayMap[key]
          ? tempMasterDisplayMap[key]
          : {};
        tempMasterDisplayMap[key][item!.valueId] = item!.valueToDisplay;
      }

      // Definimos el maestro
      this.master = { ...tempMaster };
      // Definimos el maestro de nombres para visualizar
      this.masterDisplayMap = { ...tempMasterDisplayMap };
      this.masterLoaded = true;
    } else {
      console.log('Se omite carga del maestro');
    }

    this.additionalCentersLoaded = {};

    console.log('master', this.master);
    console.log('masterDisplayMap', this.masterDisplayMap);
  }

  /**
   * Descarga el maestro filtrado por negocio.
   * @return {Promise}
   */
  private async downloadMaster(): Promise<Master[]> {
    let listMastersResult: ListMastersQuery = await this.api.ListMasters(
      this.usersService.business.value.toUpperCase()
    );
    let tempListMasters = <Master[]>listMastersResult.items;
    let nextToken = listMastersResult.nextToken;
    // Es posible que la primera query no retorne todos los datos del maestro.
    while (nextToken) {
      let loopListMastersResult: ListMastersQuery = await this.api.ListMasters(
        this.usersService.business.value.toUpperCase(),
        {},
        {},
        100,
        nextToken
      );
      tempListMasters.push(...(<Master[]>loopListMastersResult.items));
      nextToken = loopListMastersResult.nextToken;
    }

    return tempListMasters.slice();
  }

  /**
   * Retorna el maestro.
   * @return {MasterObject}
   */
  getMaster(): MasterObject {
    return { ...this.master };
  }

  /**
   * Retorna el mapa que transforma ID en
   * texto legible para el front-end.
   * @return {masterDisplayMap}
   */
  getMasterDisplayMap(): MasterDisplayMap {
    return { ...this.masterDisplayMap };
  }

  /**
   * Configura la lista de volúmenes disponibles y el número de contenedores
   * permitidos para cada volumen según el maestro.
   * @param {string} model Modelo
   */
  setValidVolumes(model: 'CISTERNA' | 'TANQUE'): void {
    const business: string = this.usersService.business.value.toUpperCase();
    let tempObject: { [index: string]: string[] } = {};
    let tempVolumes: { valueId: string; valueToDisplay: string }[] = [];
    let tempContainerNumbers: {
      [index: string]: { valueId: string; valueToDisplay: string }[];
    } = {};

    for (const item of this.master[`${model}S#CAPACITIES`]!) {
      let volumeArray: string[] = item.valueId
        .replace(`${business}_`, '')
        .split('#');
      const volume: string = volumeArray[0];
      const containers: number = volumeArray.length - 1;

      tempObject[volume] = tempObject[volume] ? tempObject[volume] : [];
      tempObject[volume].push(containers.toString());
    }

    for (const volume of Object.keys(tempObject)) {
      tempVolumes.push({
        valueId: volume,
        valueToDisplay: `${volume}m3`,
      });
      // removemos números de contenedores duplicados
      tempObject[volume] = [...new Set(tempObject[volume])];

      for (const containerNumber of tempObject[volume]) {
        tempContainerNumbers[volume] = tempContainerNumbers[volume]
          ? tempContainerNumbers[volume]
          : [];
        tempContainerNumbers[volume].push({
          valueId: containerNumber,
          valueToDisplay: `${containerNumber} compartimentos`,
        });
      }
    }

    if (model === 'TANQUE') {
      this.tanquesVolumes = tempVolumes.slice();
      this.tanquesContainerNumbers = { ...tempContainerNumbers };
    } else if (model === 'CISTERNA') {
      this.cisternasVolumes = tempVolumes.slice();
      this.cisternasContainerNumbers = { ...tempContainerNumbers };
    }
  }

  /**
   * Retorna un arreglo cuyos item son los volúmenes permitidos para tanques según
   * el maestro.
   * @param {string} model Modelo
   * @return {{valueId: string, valueToDisplay: string}[]}
   */
  getValidVolumes(
    model: 'CISTERNA' | 'TANQUE'
  ): { valueId: string; valueToDisplay: string }[] {
    switch (model) {
      case 'TANQUE':
        return this.tanquesVolumes.slice();
      case 'CISTERNA':
        return this.cisternasVolumes.slice();
    }
  }

  /**
   * Retorna un objeto con las posibles cantidades de compartimentos
   * por volumen permitidos según el maestro.
   * @param {string} model Modelo
   * @return {{[index: string]: {valueId: string, valueToDisplay: string}[]}}
   */
  getValidContainersNumber(model: 'CISTERNA' | 'TANQUE'): {
    [index: string]: { valueId: string; valueToDisplay: string }[];
  } {
    switch (model) {
      case 'TANQUE':
        return { ...this.tanquesContainerNumbers };
      case 'CISTERNA':
        return { ...this.cisternasContainerNumbers };
    }
  }

  /**
   * Retorna una lista con los ID de los centros asociados a un negocio.
   * @param {string} businessName Nombre del negocio a consultar.
   * @return {Promise<string[]>} Promesa con la lista de centros asociados al negocio.
   */
  async getUserCentersByBusiness(businessName: string): Promise<string[]> {
    let centersList: string[] = [];
    if (
      businessName ===
      this.usersService.getSelectedUser().business.toUpperCase()
    ) {
      this.getMaster()['CENTERS'].forEach((masterItem: Master): void => {
        // Como el maestro ya puede tener cargados otros negocios,
        // filtramos solo los centros que correspondan.
        if (masterItem.valueId.startsWith(businessName)) {
          centersList.push(masterItem.valueId);
        }
      });
    } else {
      let listMastersResult: ListMastersQuery = await this.api.ListMasters(
        businessName,
        { beginsWith: `CENTERS#${businessName}_` }
      );
      let tempListMasters = <Master[]>listMastersResult.items;
      let nextToken = listMastersResult.nextToken;

      // Es posible que la primera query no retorne todos los datos del maestro.
      while (nextToken) {
        let loopListMastersResult: ListMastersQuery =
          await this.api.ListMasters(
            businessName,
            { beginsWith: `CENTERS#${businessName}_` },
            {},
            100,
            nextToken
          );
        tempListMasters.push(...(<Master[]>loopListMastersResult.items));
        nextToken = loopListMastersResult.nextToken;
      }

      tempListMasters.forEach((masterItem: Master): void => {
        centersList.push(masterItem.valueId);
      });

      // Si no se han cargado previamente los centros, se actualizan los maestros
      if (!this.additionalCentersLoaded[businessName]) {
        tempListMasters.forEach((masterItem: Master): void => {
          this.master.CENTERS.push(masterItem);
          this.masterDisplayMap.CENTERS = {
            ...this.masterDisplayMap.CENTERS,
            [masterItem.valueId]: masterItem.valueToDisplay,
          };
        });

        // Agregamos el centro ya cargado
        this.additionalCentersLoaded = {
          ...this.additionalCentersLoaded,
          [businessName]: true,
        };
      }
    }

    return centersList.slice();
  }

  /**
   * Retorna un color dependiendo del estado de la entidad.
   * @param {string} status Estado.
   * @return {string}
   */
  getSatusColor(status: string): string {
    let statusColor: string;
    if (status.endsWith(`_${appConstants.entity.codes.toBeApproved}`)) {
      statusColor = appConstants.colors.statuses.toBeApproved;
    } else if (status.endsWith(`_${appConstants.entity.codes.available}`)) {
      statusColor = appConstants.colors.statuses.available;
    } else if (status.endsWith(`_${appConstants.entity.codes.blocked}`)) {
      statusColor = appConstants.colors.statuses.blocked;
    } else if (status.endsWith(`_${appConstants.entity.codes.inProcess}`)) {
      statusColor = appConstants.colors.statuses.inProcess;
    } else if (status.endsWith(`_${appConstants.entity.codes.rejected}`)) {
      statusColor = appConstants.colors.statuses.rejected;
    } else {
      statusColor = appConstants.colors.statuses.rejected;
    }

    return statusColor;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado.
   * @param {string} status Estado.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    let imageSrc: string = 'assets/images/icons/';

    if (status.endsWith(`_${appConstants.entity.codes.toBeApproved}`)) {
      imageSrc += 'toBeApproved';
    } else if (status.endsWith(`_${appConstants.entity.codes.available}`)) {
      imageSrc += 'available';
    } else if (status.endsWith(`_${appConstants.entity.codes.blocked}`)) {
      imageSrc += 'blocked';
    } else if (status.endsWith(`_${appConstants.entity.codes.inProcess}`)) {
      imageSrc += 'inProcess';
    } else if (status.endsWith(`_${appConstants.entity.codes.rejected}`)) {
      imageSrc += 'rejected';
    } else {
      imageSrc += 'rejected';
    }

    return imageSrc + '.svg';
  }
}
