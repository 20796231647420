import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  APIService,
  CreateFormQuestionInput,
  CreateRozFormInput,
  FormQuestion,
  FormQuestionTemplate,
  RozForm,
  UpdateFormQuestionInput,
  UpdateRozFormInput,
} from '../../../app-sync.service';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { RtcEvaluationsService } from '../rtc-evaluations.service';
import { FormsService } from '../../../shared/services/forms.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { SelectedOptions } from '../../../shared/interfaces/type-options';
import { interval, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { appConstants } from '../../../shared/constants/constants';
import { ModalsService } from '../../../shared/modals/modals.service';
import { UsersService } from '../../users/users.service';
import { DocumentsService } from '../../documents/documents.service';
import { QuestionFromForms } from '../../../shared/interfaces/question-from-forms';
import { ValidatorsService } from '../../../shared/services/validators.service';

@Component({
  selector: 'app-rtc-evaluation',
  templateUrl: './rtc-evaluation.component.html',
  styleUrls: ['./rtc-evaluation.component.css'],
})
export class RtcEvaluationComponent implements OnInit, OnDestroy {
  @ViewChild('rtcSubmitModal', { static: false }) rtcSubmitModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('documentViewerModal', { static: false }) documentViewerModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('commentModal', { static: false }) commentModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('deleteImageModal', { static: false }) deleteImageModal:
    | TemplateRef<any>
    | undefined;
  isLoading: boolean = false;
  update: string = '';
  selectedOptions: SelectedOptions =
    this.rtcEvaluationsService.selectedOptions.value;
  questions: FormQuestionTemplate[] | FormQuestion[] = [];
  generalComment: string | null = null;
  evaluationForm: FormGroup;
  rozFormExists: boolean = false;
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';
  totalScore: number = 0;
  totalComments: number = 0;
  totalImages: number = 0;
  totalImagesToUpload: number = 0;
  evaluationResult: 'Aprobado' | 'Reprobado' = 'Reprobado';
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  showFormControls: boolean = false;
  isGettingURL: boolean = false;
  imageSource: string = '';
  commentForm: FormGroup;
  currentRozStatus: string = '';
  timeInterval: Observable<number> = interval(1000);

  private optionsSelectedSubscription: Subscription = new Subscription();
  private timeIntervalSubscription: Subscription = new Subscription();

  get questionsControl() {
    return (<FormArray>this.evaluationForm.get('questions')).controls;
  }
  get commentControl() {
    return this.commentForm;
  }

  constructor(
    private formsService: FormsService,
    private feedbacksService: FeedbacksService,
    private api: APIService,
    private rtcEvaluationsService: RtcEvaluationsService,
    private modalsService: ModalsService,
    private usersService: UsersService,
    private validatorsService: ValidatorsService,
    private documentsService: DocumentsService
  ) {
    this.evaluationForm = new FormGroup({
      comment: new FormControl(null),
      questions: this.formsService.formQuestionFormArrayInitialization(
        [],
        '',
        ''
      ),
    });
    this.commentForm = new FormGroup({
      comment: new FormControl(null),
    });
  }

  async ngOnInit(): Promise<void> {
    this.selectedOptions = this.rtcEvaluationsService.selectedOptions.value;
    // Si hay opciones seleccionadas cargamos el formulario
    if (
      this.rtcEvaluationsService.selectedOptionsDefined &&
      this.rtcEvaluationsService.selectedEntityExists
    ) {
      await this.refreshRozForm();
    }
    // Subscripción para actualizar formulario si cambian las opciones seleccionadas
    this.optionsSelectedSubscription =
      this.rtcEvaluationsService.selectedOptions.subscribe(
        async (selectedOptions: SelectedOptions): Promise<void> => {
          this.selectedOptions = selectedOptions;
          if (
            this.rtcEvaluationsService.selectedOptionsDefined &&
            this.rtcEvaluationsService.selectedEntityExists
          ) {
            // Si el vehículo existe cargamos la evaluación.
            await this.refreshRozForm();
          } else if (!this.rtcEvaluationsService.selectedEntityExists) {
            // Si el vehículo no existe, borramos la evaluación cargada previamente.
            this.showFormControls = false;
            this.questions = [];
            this.generalComment = null;
            this.initEvaluationForm();
          }
        }
      );
  }

  /**
   * Refresca el formulario cargado.
   * @return {Promise<void>}
   * @private
   */
  private async refreshRozForm(): Promise<void> {
    // El setTimeout es un workaround para evitar el error: https://v17.angular.io/errors/NG0100
    setTimeout((): void => {
      this.rtcEvaluationsService.disableLoadButton.next(true);
    }, 0);
    this.isLoading = true;
    await this.getRozForm().then((): void => {
      this.isLoading = false;
      // El setTimeout es un workaround para evitar el error: https://v17.angular.io/errors/NG0100
      setTimeout((): void => {
        this.rtcEvaluationsService.disableLoadButton.next(false);
      }, 0);
    });
  }

  /**
   * Contiene la lógica para cargar un formulario nuevo
   * o cargar un formulario preexistente.
   * @return {Promise<void>}
   * @private
   */
  private async getRozForm(): Promise<void> {
    const licensePlate: string = this.selectedOptions.licensePlate;

    // Refrescamos el formulario
    await this.rtcEvaluationsService.refreshRozForm();
    // Obtenemos el formulario (si existe)
    const rozForm: RozForm = this.rtcEvaluationsService.getRozForm();

    // ¿No existe el formulario?
    if (Object.keys(rozForm).length === 0) {
      console.log('Cargar Formulario Nuevo');
      this.rozFormExists = false;
      this.questions =
        await this.rtcEvaluationsService.getListFormQuestionTemplates();
      this.initEvaluationForm();
      this.showFormControls = true;
    } else if (rozForm.status === 'APPROVED') {
      // Formulario ya está aprobado por este mes.
      this.feedbacksService.showFeedback(
        `Evaluación del vehículo ${licensePlate} ya fue aprobada para este mes`,
        'info'
      );
      this.showFormControls = false;
    } else if (rozForm.status === 'PROCESSING' || rozForm.status === 'SENT') {
      // Formulario en proceso.
      this.feedbacksService.showFeedback(
        `La evaluación del vehículo ${licensePlate} se está procesando, intente nuevamente en unos minutos`,
        'info'
      );
      this.showFormControls = false;
    } else {
      // El formulario existe y está reprobado.
      // Cargamos las preguntas del formulario en la pantalla.
      console.log('Cargar Formulario existente');
      this.rozFormExists = true;
      this.currentRozStatus =
        rozForm.status === 'SAVED' ? 'Guardado' : 'Reprobado';
      this.generalComment = rozForm.comment ? rozForm.comment : null;
      this.questions = <FormQuestion[]>rozForm.questions!.items;
      this.initEvaluationForm();
      this.showFormControls = true;
    }
  }

  /**
   * Inicializa el formulario para crear o
   * editar una evaluación.
   * @private
   */
  private initEvaluationForm(): void {
    // Inicializa el formulario con preguntas existentes o desde la plantilla.
    this.evaluationForm = new FormGroup({
      comment: new FormControl<string | null>(this.generalComment),
      questions: this.formsService.formQuestionFormArrayInitialization(
        <FormQuestionTemplate[]>this.questions,
        this.selectedOptions.licensePlate,
        this.rtcEvaluationsService.formDate
      ),
    });

    // Calculamos la puntuación inicial
    this.updateScore();
  }

  /**
   * Inicializa el formulario de comentario para preguntas.
   * @param {string | null} comment Comentario actual de la pregunta.
   * @private
   */
  private initCommentForm(comment: string | null): void {
    // Inicialización del formulario
    this.commentForm = new FormGroup({
      comment: new FormControl<string | null>(comment, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(300),
      ]),
    });
  }

  /**
   * Asigna el archivo al control imageSourceFile
   * de la pregunta.
   * @param {Event} event Evento input.
   * @param {number} index Índice de la pregunta en el arreglo questions.
   */
  onChangeImageFile(event: Event, index: number): void {
    // Asignamos el archivo al FormControl 'imageSourceFile'
    const target = event.target as HTMLInputElement;
    console.log(' --- onChangeImageFile ---');
    console.log(target);
    console.log(target.files![0]);
    if (target.files!.length > 0) {
      const file: File = target.files![0];
      // Asignamos el archivo
      (<FormArray>this.evaluationForm.get('questions'))
        .at(index)
        .patchValue({ imageSourceFile: file });

      // Creamos un lector de archivos local.
      const fileReader: FileReader = new FileReader();
      fileReader.onload = (): void => {
        (<FormArray>this.evaluationForm.get('questions'))
          .at(index)
          .patchValue({ imageLocalPath: fileReader.result });
      };
      fileReader.readAsDataURL(file);
    }
  }

  /**
   * Actualiza la puntuación y el estado que observa el usuario.
   */
  updateScore(): void {
    let criticalNumber: number = 0;
    let moderateNumber: number = 0;
    let mildNumber: number = 0;
    let scoreCheck: number = 0;
    this.totalScore = 0;

    this.evaluationForm.value.questions.forEach((question: any): void => {
      scoreCheck += question.weight;

      if (question.answer === 'No') {
        switch (question.criticality) {
          case 'LEVE':
            mildNumber += 1;
            break;
          case 'MODERADO':
            moderateNumber += 1;
            break;
          case 'CRITICO':
            criticalNumber += 1;
            break;
          default:
            break;
        }
      } else if (question.answer === 'Si') {
        this.totalScore += question.weight;
      }
    });

    // Criterios para Reprobar:
    //  - 1 respuesta No con nivel Crítico.
    //  - 3 respuestas No con nivel Moderado.
    //  - 5 respuestas No con nivel Leve.
    //  - Ponderación < 80%.
    if (
      criticalNumber >= 1 ||
      moderateNumber >= 3 ||
      mildNumber >= 5 ||
      this.totalScore < 80
    ) {
      this.evaluationResult = 'Reprobado';
    } else {
      this.evaluationResult = 'Aprobado';
    }
  }

  /**
   * Levanta el modal para aceptar la acción que quiere realizar el usuario
   * y ejecuta el método de creación/actualización del formulario
   * si se acepta el modal.
   * @param {string} action Acción que ejecutó el usuario.
   * @param {string} comment Comentario general de la evaluación.
   */
  async onSubmitEvaluation(
    action: 'save' | 'send',
    comment: string
  ): Promise<void> {
    if (!this.evaluationForm.valid && action === 'send') {
      this.feedbacksService.showFeedback(
        'Debe contestar todas las preguntas del formulario antes de poder enviarlo.',
        'danger'
      );
      return;
    }

    this.initCommentForm(comment);
    const licensePlate: string = this.selectedOptions.licensePlate;
    if (action === 'save') {
      this.modalTitle = appConstants.rozForm.modalMessages.saveForm.title;
      this.modalQuestion =
        appConstants.rozForm.modalMessages.saveForm.question.replace(
          '_',
          licensePlate
        );
      this.modalMessage = appConstants.rozForm.modalMessages.saveForm.message;
    } else {
      this.modalTitle = appConstants.rozForm.modalMessages.submitForm.title;
      this.modalQuestion =
        appConstants.rozForm.modalMessages.submitForm.question.replace(
          '_',
          licensePlate
        );
      this.modalMessage = appConstants.rozForm.modalMessages.submitForm.message;
    }

    // Se calcula el total de comentarios e imágenes.
    this.totalComments = 0;
    this.totalImages = 0;
    this.totalImagesToUpload = 0;
    let totalImagesUploaded: number = 0;
    this.evaluationForm.value.questions.forEach((question: any): void => {
      if (question.comment !== null && question.comment !== '') {
        this.totalComments += 1;
      }
      if (question.imageS3Path !== null && question.imageS3Path !== '') {
        totalImagesUploaded += 1;
      }
      if (question.imageFile !== null && question.imageFile !== '') {
        this.totalImagesToUpload += 1;
      }
      this.totalImages = totalImagesUploaded + this.totalImagesToUpload;
    });

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.rtcSubmitModal
    );

    if (modalResult) {
      this.isLoading = true;
      this.evaluationForm.patchValue({
        comment: this.commentForm.get('comment')?.value,
      });
      await this.onAcceptSubmitEvaluation(action).then((): void => {
        this.isLoading = false;
      });
    }
  }

  /**
   * Crea/Actualiza un formulario en la BD
   * @param {string} action Acción que ejecutó el usuario.
   */
  private async onAcceptSubmitEvaluation(
    action: 'save' | 'send'
  ): Promise<void> {
    const questionList: QuestionFromForms[] =
      this.evaluationForm.value.questions.slice();
    const uploadDateTimestamp: string = Math.floor(
      Date.now() / 1000
    ).toString();

    if (this.rozFormExists) {
      this.feedbacksService.showFeedback(`Guardando evaluación`, 'info');
      // 1. Actualizamos el formulario en la BD en estado Procesando
      const updateRozFormInput: UpdateRozFormInput = {
        formId: this.rtcEvaluationsService.formId,
        formDate: this.rtcEvaluationsService.formDate,
        status: 'PROCESSING',
        comment: this.evaluationForm.value.comment,
        updatedBy: this.usersService.getActiveUser().userId,
      };

      await this.api
        .UpdateRozForm(updateRozFormInput)
        .then(async () => {
          // 2. Actualizamos las preguntas.
          this.updateQuestions(questionList, uploadDateTimestamp);
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al actualizar evaluación del vehículo ${this.selectedOptions.licensePlate}`
          );
        });
    } else {
      this.feedbacksService.showFeedback(`Creando evaluación`, 'info');

      // 1. Creamos un formulario en la BD en estado Procesando
      const createRozFormInput: CreateRozFormInput = {
        formId: this.rtcEvaluationsService.formId,
        formDate: this.rtcEvaluationsService.formDate,
        status: 'PROCESSING',
        comment: this.evaluationForm.value.comment,
        createdBy: this.usersService.getActiveUser().userId,
        updatedBy: this.usersService.getActiveUser().userId,
      };

      await this.api
        .CreateRozForm(createRozFormInput)
        .then(async (): Promise<void> => {
          // 2. Creamos las preguntas.
          this.createQuestions(questionList, uploadDateTimestamp);
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al crear evaluación del vehículo ${this.selectedOptions.licensePlate}`
          );
        });
    }

    this.rtcEvaluationsService.resetSelectedOptions();
    if (this.totalImagesToUpload > 0) {
      this.documentsService.isUploadingDocuments.next(true);
    }
    // 3. Preguntamos cada segundo si las imágenes fueron cargadas, para poder
    // enviar o guardar el formulario.
    this.timeIntervalSubscription = this.timeInterval.subscribe(
      async (): Promise<void> => {
        await this.saveOrSentForm(action);
      }
    );
  }

  /**
   * Actualiza el formulario si no hay imágenes siendo cargadas.
   * @param {string} action Acción que ejecutó el usuario.
   */
  private async saveOrSentForm(action: 'save' | 'send'): Promise<void> {
    if (!this.documentsService.isUploadingDocuments.value) {
      // 4. Actualizamos el formulario
      const updateRozFormInput: UpdateRozFormInput = {
        formId: this.rtcEvaluationsService.formId,
        formDate: this.rtcEvaluationsService.formDate,
        status: action === 'save' ? 'SAVED' : 'SENT',
        updatedBy: this.usersService.getActiveUser().userId,
      };
      await this.rtcEvaluationsService.updateRozForm(updateRozFormInput);

      this.feedbacksService.showFeedback(
        `Evaluación ${
          action === 'save' ? 'Guardada' : 'Enviada'
        } correctamente.`,
        'success'
      );

      // Reiniciamos el formulario
      this.rtcEvaluationsService.resetRozForm();
      this.showFormControls = false;
      this.questions = [];
      this.generalComment = null;
      this.initEvaluationForm();
      this.timeIntervalSubscription.unsubscribe();
    }
  }

  /**
   * Crea una lista de preguntas en la BD.
   * @param {QuestionFromForms[]} questionList Lista de preguntas del formulario de evaluación.
   * @param {string} uploadDateTimestamp Timestamp de carga.
   */
  private createQuestions(
    questionList: QuestionFromForms[],
    uploadDateTimestamp: string
  ): void {
    for (let question of questionList) {
      // 2.1 Creamos la pregunta del formulario
      const createRozFormQuestionInput: CreateFormQuestionInput = {
        formQuestionId: question.formQuestionId,
        question: question.question,
        criticality: question.criticality,
        weight: question.weight,
        order: question.order,
        answer: question.answer,
        comment: question.comment,
        rozFormQuestionsFormId: this.rtcEvaluationsService.formId,
        rozFormQuestionsFormDate: this.rtcEvaluationsService.formDate,
      };
      this.rtcEvaluationsService
        .createRozFormQuestion(createRozFormQuestionInput)
        .then(() => {
          // 2.2 cargamos la imagen de la pregunta, si aplica.
          if (question.imageSourceFile) {
            this.uploadImage(question, uploadDateTimestamp);
          }
        });
    }
  }

  /**
   * Actualiza una lista de preguntas en la BD.
   * @param {QuestionFromForms[]} questionList Lista de preguntas del formulario de evaluación.
   * @param {string} uploadDateTimestamp Timestamp de carga.
   */
  private updateQuestions(
    questionList: QuestionFromForms[],
    uploadDateTimestamp: string
  ): void {
    for (let question of questionList) {
      // 2.1 Actualizamos la pregunta del formulario
      const updateRozFormQuestionInput: UpdateFormQuestionInput = {
        formQuestionId: question.formQuestionId,
        question: question.question,
        criticality: question.criticality,
        weight: question.weight,
        order: question.order,
        answer: question.answer,
        comment: question.comment,
      };
      this.rtcEvaluationsService
        .updateRozFormQuestion(updateRozFormQuestionInput)
        .then(() => {
          // 2.2 cargamos la imagen de la pregunta, si aplica.
          if (question.imageSourceFile) {
            this.uploadImage(question, uploadDateTimestamp);
          }
        });
    }
  }

  /**
   * Carga la imagen de una pregunta
   * @param {QuestionFromForms} question Pregunta.
   * @param {string} uploadDateTimestamp timestamp de carga.
   */
  private uploadImage(
    question: QuestionFromForms,
    uploadDateTimestamp: string
  ): void {
    const inputImage = {
      documentId: question.formQuestionId,
      documentMasterValueId: question.formQuestionId.split('#')!.pop()!,
      documentName: uploadDateTimestamp,
      documentSourceFile: question.imageSourceFile,
    };

    this.documentsService
      .uploadImage(
        inputImage,
        this.rtcEvaluationsService.formDate,
        uploadDateTimestamp,
        this.selectedOptions.business,
        this.selectedOptions.type,
        this.selectedOptions.licensePlate,
        question.order
      )
      .then();
  }

  /**
   * Permite visualizar la imagen de una pregunta y borrarla.
   * @param {number} index Índice de la pregunta.
   */
  async onViewImage(index: number): Promise<void> {
    this.isGettingURL = true;

    const question: AbstractControl<any, any> = (<FormArray>(
      this.evaluationForm.get('questions')
    )).at(index);
    const imageS3Path: string | null = question.get('imageS3Path')?.value;
    const imageLocalPath: string | null = question.get('imageLocalPath')?.value;

    this.modalTitle = question.get('question')?.value;

    if (this.questionHasLocalImage(imageLocalPath)) {
      this.imageSource = <string>imageLocalPath;
    } else if (this.questionHasS3Image(imageS3Path)) {
      await this.documentsService
        .getImagePreSignedURL(<string>imageS3Path)
        .then(async (): Promise<void> => {
          this.imageSource = this.documentsService.documentPreSignedURL;
        });
    }

    await this.modalsService
      .showModal(<TemplateRef<any>>this.documentViewerModal, true)
      .then((value: boolean): void => {
        // Verificamos si el usuario presionó el botón de Borrar
        if (!value && this.modalsService.dismissedReason === 'with: Delete') {
          this.modalTitle =
            appConstants.rozForm.modalMessages.deleteImage.title;
          this.modalQuestion =
            appConstants.rozForm.modalMessages.deleteImage.question.replace(
              '_',
              question.get('question')?.value
            );
          this.modalMessage =
            appConstants.rozForm.modalMessages.deleteImage.message;

          this.modalsService
            .showModal(<TemplateRef<any>>this.deleteImageModal)
            .then((value: boolean): void => {
              if (value) {
                // Eliminamos todos los datos asociados a imágenes locales.
                (<FormArray>this.evaluationForm.get('questions'))
                  .at(index)
                  .patchValue({ imageFile: null });
                (<FormArray>this.evaluationForm.get('questions'))
                  .at(index)
                  .patchValue({ imageSourceFile: null });
                (<FormArray>this.evaluationForm.get('questions'))
                  .at(index)
                  .patchValue({ imageLocalPath: null });
                (<FormArray>this.evaluationForm.get('questions'))
                  .at(index)
                  .patchValue({ imageS3Path: null });

                // Si la pregunta tiene imagen en S3 actualizamos la pregunta en la BD
                if (this.questionHasS3Image(imageS3Path)) {
                  const updateFormQuestionInput: UpdateFormQuestionInput = {
                    formQuestionId: question.get('formQuestionId')?.value,
                    imageS3Path: null,
                  };
                  this.rtcEvaluationsService.deleteImageFromRozFormQuestion(
                    updateFormQuestionInput
                  );
                }
              }
            });
        }
      });
  }

  /**
   * Levanta el modal para ingresar un comentario de una pregunta.
   * @param {string | null} comment Comentario de la pregunta.
   * @param {string} question Enunciado de la pregunta.
   * @param {number} index Índice de la pregunta en el arreglo.
   */
  async onAddComment(
    comment: string | null,
    question: string,
    index: number
  ): Promise<void> {
    this.initCommentForm(comment);
    this.modalTitle = appConstants.rozForm.modalMessages.addComment.title;
    this.modalQuestion =
      appConstants.rozForm.modalMessages.addComment.question.replace(
        '_',
        question
      );
    this.modalMessage = appConstants.rozForm.modalMessages.addComment.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.commentModal
    );

    if (modalResult) {
      (<FormArray>this.evaluationForm.get('questions'))
        .at(index)
        .patchValue({ comment: this.commentForm.get('comment')?.value });
    }
  }

  /**
   * Levanta el modal para ingresar un comentario general de la evaluación.
   * @param {string | null} comment Comentario de la pregunta.
   */
  async onAddGeneralComment(comment: string | null): Promise<void> {
    this.initCommentForm(comment);
    this.modalTitle = appConstants.rozForm.modalMessages.addComment.title;
    this.modalQuestion = 'Observación general de la evaluación.';
    this.modalMessage = appConstants.rozForm.modalMessages.addComment.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.commentModal
    );

    if (modalResult) {
      this.evaluationForm.patchValue({
        comment: this.commentForm.get('comment')?.value,
      });
    }
  }

  /**
   * Actualiza la variable que monitorea si se stá descargando una imagen.
   */
  imageLoaded(): void {
    this.isGettingURL = false;
  }

  /**
   * Retorna un booleano que contesta a la pregunta
   * ¿la pregunta tiene imagen asociada en S3?
   * @param {string} imageS3Path Ruta de la imagen en S3.
   */
  questionHasS3Image(imageS3Path: string | null): boolean {
    return imageS3Path !== null && imageS3Path !== '';
  }

  /**
   * Retorna un booleano que contesta a la pregunta
   * ¿la pregunta tiene imagen asociada en local?
   * @param {string} imageLocalPath Ruta de la imagen en local.
   */
  questionHasLocalImage(imageLocalPath: string | null): boolean {
    return imageLocalPath !== null && imageLocalPath !== '';
  }

  /**
   * Retorna la clase para el ícono de comentario
   * @return {string}
   */
  getCommentClass(comment: string | null): string {
    if (comment !== null && comment !== '') {
      return 'with-content';
    } else {
      return 'without-content';
    }
  }

  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm(): void {
    console.log('-- Current Form --');
    console.log(this.evaluationForm);
  }

  /**
   * Retorna un color dependiendo del estado de la evaluación actual.
   * @return {string}
   */
  getColor(): string {
    return this.rtcEvaluationsService.getSatusColor();
  }

  /**
   * Retorna una imagen dependiendo del estado de la evaluación actual.
   * @return {string}
   */
  getImageSrc(): string {
    return this.rtcEvaluationsService.getRozFormImageSrc();
  }

  ngOnDestroy(): void {
    this.optionsSelectedSubscription.unsubscribe();
    console.log('rtc-evaluation.component subscriptions removed.');
  }
}
