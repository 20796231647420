import { Component, OnDestroy, OnInit } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DocumentsService } from './pages/documents/documents.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title: string = 'Roz';
  isUploadingDocuments: boolean = false;

  private isUploadingDocumentsSubscription: Subscription = new Subscription();

  constructor(private documentsService: DocumentsService) {
    if (environment.datadog.enabled) {
      datadogRum.init({
        applicationId: environment.datadog.app,
        clientToken: environment.datadog.token,
        service: environment.datadog.service,
        env: environment.env,
        version: environment.version,
        site: 'datadoghq.com',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }

    this.isUploadingDocuments =
      this.documentsService.isUploadingDocuments.value;
  }

  ngOnInit(): void {
    // Suscripción a la variable que indica si se están cargando documentos.
    this.isUploadingDocumentsSubscription =
      this.documentsService.isUploadingDocuments.subscribe(
        (value: boolean): void => {
          this.isUploadingDocuments = value;
        }
      );
  }

  ngOnDestroy(): void {
    this.isUploadingDocumentsSubscription.unsubscribe();
    console.log('app.component subscriptions removed.');
  }
}
