import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  APIService,
  Cisterna,
  Document,
  Envasado,
  EvaluatedRozForm,
  Semirremolque,
  Tanque,
  Tracto,
  Vehicle,
} from 'src/app/app-sync.service';
import { appConstants } from 'src/app/shared/constants/constants';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { DocumentsService } from '../../documents/documents.service';
import { RtcEvaluationsService } from '../../rtc-evaluations/rtc-evaluations.service';
import { UsersService } from '../../users/users.service';
import { MasterService } from '../../master/master.service';
import { ApiRequestsService } from 'src/app/shared/services/api-requests.service';

@Component({
  selector: 'app-historial-rtc',
  templateUrl: './historial-rtc.component.html',
  styleUrls: ['./historial-rtc.component.css'],
})
export class HistorialRTCComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  listEvaluations: any[] = []; //TODO Aqui definir el tipo de los  datos
  filteredEvaluations: any[] = [];
  filterForm: FormGroup;
  isGettingEvaluations: boolean = false;
  modalTitle: string = '';
  pdfSource: string = '';
  @ViewChild('documentViewer', { static: false }) documentViewer:
    | TemplateRef<any>
    | undefined;
  isGettingURL: boolean = false;
  business: string = '';
  uniqueFormIds: any[] = [];
  readonly master: any = {};
  CENTERS: any;
  CARRIERS: any;
  results: Array<{ display: string; value: string }> = [
    { display: 'Aprobado', value: 'APPROVED' },
    { display: 'Rechazado', value: 'REPROVED' },
  ];
  constructor(
    private fb: FormBuilder,
    private api: APIService,
    private rtcEvaluationsService: RtcEvaluationsService,
    private documentsService: DocumentsService,
    private modalsService: ModalsService,
    private usersService: UsersService,
    private masterService: MasterService,
    private apiService: ApiRequestsService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.filterForm = this.fb.group({
      dateRangeStart: [''],
      dateRangeEnd: [''],
      plate: [''],
      carrier: [''],
      center: [''],
      result: [''],
      sapID: [''],
    });
    this.master = this.masterService.getMaster();
    this.CENTERS = this.master['CENTERS'];
    this.CARRIERS = this.master['CARRIERS'];
  }
  ngOnInit(): void {
    this.isGettingEvaluations = true;
    this.usersService.business.subscribe({
      next: (business) => {
        this.business = business;
        this.getEvaluations();
      },
    });
  }
  /**
   * obtiene el historial
   * @param nextToken
   */
  getEvaluations(nextToken?: string): void {
    this.api
      .EvaluatedRozFormsByBusinessAndEvaluatedAt(
        this.business.toUpperCase(),
        undefined,
        undefined,
        undefined,
        100,
        nextToken
      )
      .then((list) => {
        this.listEvaluations.push(...list.items);
        if (list.nextToken) {
          this.getEvaluations(list.nextToken);
        } else {
          this.getUniquePlates();
          this.refreshRozForm(this.uniqueFormIds);
        }
      });
  }
  /**
   * obtiene ids unicos para traer detalle de las patentes
   */
  getUniquePlates(): void {
    this.uniqueFormIds = Array.from(
      new Set(this.listEvaluations.map((evaluation) => evaluation.formId))
    );
  }

  /**
   *separa de manera unica las patentes antes de traer los detalles desde el servidor
   * @param formIds
   */
  async refreshRozForm(formIds: Array<string>): Promise<void> {
    let promises = formIds.map(async (element) => {
      let splited: Array<string> = element.split('#');
      let vehicleType = splited[2];
      let plate = splited[3];
      return this.getEntity(vehicleType, plate);
    });

    await Promise.all(promises).then((vehicles) => {
      this.listEvaluations.forEach((evaluation) => {
        let match = vehicles.find((result) =>
          evaluation.formId.includes(result.licensePlate)
        );
        if (match) {
          evaluation.vehicle = match;
        }
      });

      this.listEvaluations = this.listEvaluations.map((evaluation) => ({
        ...evaluation,
        evaluatedDate: new Date(
          evaluation.evaluatedAt.split('/').reverse().join('-')
        ),
      }));
      this.listEvaluations.sort(
        (a, b) => b.evaluatedDate.getTime() - a.evaluatedDate.getTime()
      );

      this.filteredEvaluations = [...this.listEvaluations];
      this.isGettingEvaluations = false;
    });
  }
  /**
   * realiza llamadas al servidor para obetener los datos del vehiculo
   * basado en el tipo y la patente
   * @param type
   * @param licensePlate
   * @returns {Promise}
   */
  private async getEntity(type: string, licensePlate: string): Promise<any> {
    switch (type) {
      case 'VEHICLE':
        return <Vehicle>await this.api.GetVehicle(licensePlate);
      case 'ENVASADO':
        return <Envasado>await this.api.GetEnvasado(licensePlate);
      case 'CISTERNA':
        return <Cisterna>await this.api.GetCisterna(licensePlate);
      case 'TRACTO':
        return <Tracto>await this.api.GetTracto(licensePlate);
      case 'TANQUE':
        return <Tanque>await this.api.GetTanque(licensePlate);
      case 'SEMIRREMOLQUE':
        return <Semirremolque>await this.api.GetSemirremolque(licensePlate);
    }
  }
  /**
   * ejecuta llamadas para filtrar por fechas si es necesario
   * @param nextToken
   */
  onFilter(nextToken?: string): void {
    this.isGettingEvaluations = true;
    let startDate = this.filterForm.get('dateRangeStart')?.value;
    let endDate = this.filterForm.get('dateRangeEnd')?.value;
    if (startDate && endDate) {
      this.api
        .EvaluatedRozFormsByBusinessAndEvaluatedAt(
          'LUBRICANTS',
          {
            between: [startDate, endDate],
          },
          undefined,
          undefined,
          100,
          nextToken
        )
        .then((list) => {
          this.handleResponseAndApllyFilters(list, nextToken);
        });
    } else {
      this.api
        .EvaluatedRozFormsByBusinessAndEvaluatedAt(
          this.business.toUpperCase(),
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        )
        .then((list) => {
          this.handleResponseAndApllyFilters(list, nextToken);
        });
    }
  }

  handleResponseAndApllyFilters(list: any, nextToken?: string) {
    if (!nextToken) {
      this.listEvaluations = [];
      this.filteredEvaluations = [];
    }
    this.listEvaluations.push(...list.items);
    if (list.nextToken) {
      this.onFilter(list.nextToken);
    } else {
      this.getUniquePlates();
      this.refreshRozForm(this.uniqueFormIds).then(() => {
        this.localFilters();
      });
    }
  }

  /**
   * aplica los filtros de manera local a la respuesta del servidor
   * @returns {void}
   */
  localFilters(): void {
    this.isGettingEvaluations = true;
    this.filteredEvaluations = [];
    this.filteredEvaluations = [...this.listEvaluations];
    let plate = this.filterForm.get('plate')?.value;
    let carrier = this.filterForm.get('carrier')?.value;
    let center = this.filterForm.get('center')?.value;
    let result = this.filterForm.get('result')?.value;
    let sapID = this.filterForm.get('sapID')?.value;
    console.log(center);
    if (plate)
      this.filteredEvaluations = this.filteredEvaluations.filter(
        (eva) => eva.vehicle.licensePlate === plate
      );
    if (carrier)
      this.filteredEvaluations = this.filteredEvaluations.filter(
        (eva) => eva.vehicle.company === carrier
      );
    if (center)
      this.filteredEvaluations = this.filteredEvaluations.filter(
        (eva) => String(eva.vehicle.center).split('_')[1] === center
      );
    if (result)
      this.filteredEvaluations = this.filteredEvaluations.filter(
        (eva) => eva.status === result
      );
    if (sapID)
      this.filteredEvaluations = this.filteredEvaluations.filter((eva) =>
        eva.vehicle.sapId.includes(sapID)
      );
    this.isGettingEvaluations = false;
    console.log(this.filteredEvaluations);
  }
  /**
   * Llama al servicio de descarga de documentos.
   * @param {EvaluatedRozForm} evaluation  a descargar.
   * @return {Promise}
   */
  async onDownloadDocument(evaluation: any): Promise<void> {
    let document: Document = appConstants.document.initialization;

    // Agregamos la fecha al nombre en formato DDMMYYYYHHMMSS
    const valueDate: Date = new Date(evaluation.updatedAt);
    const dateArray: string[] = valueDate.toLocaleDateString().split('/');
    let formattedDate: string = dateArray[1].padStart(2, '0');
    formattedDate += dateArray[0].padStart(2, '0');
    formattedDate += dateArray[2];
    formattedDate += valueDate.toTimeString().split(' ')[0].replaceAll(':', '');

    document.name = `Evaluación RTC - ${formattedDate}`;
    document.s3Path = evaluation.s3Path;
    switch (evaluation.vehicle.__typename.toUpperCase()) {
      case 'VEHICLE':
        document.vehicleDocumentsVehicleId = evaluation.vehicle.licensePlate;
        break;
      case 'TRAILER':
        document.trailerDocumentsTrailerId = evaluation.vehicle.licensePlate;
        break;
      case 'ENVASADO':
        document.envasadoDocumentsEnvasadoId = evaluation.vehicle.licensePlate;
        break;
      case 'CISTERNA':
        document.cisternaDocumentsCisternaId = evaluation.vehicle.licensePlate;
        break;
      case 'TRACTO':
        document.tractoDocumentsTractoId = evaluation.vehicle.licensePlate;
        break;
      case 'TANQUE':
        document.tanqueDocumentsTanqueId = evaluation.vehicle.licensePlate;
        break;
      case 'SEMIRREMOLQUE':
        document.semirremolqueDocumentsSemirremolqueId =
          evaluation.vehicle.licensePlate;
        break;
      default:
        console.log(
          `${evaluation.vehicle.__typename} no encontrado entre las opciones.`
        );
        break;
    }
    await this.documentsService.downloadDocument(document);
  }

  /**
   * Abre el modal de visualización de un documento.
   * @param {EvaluatedRozForm} evaluation Documento a visualizar.
   * @return {Promise}
   */
  async onViewDocument(evaluation: EvaluatedRozForm): Promise<void> {
    this.isGettingURL = true;
    let document: Document = appConstants.document.initialization;

    this.modalTitle = 'Evaluación RTC';
    document.name = this.modalTitle;
    document.s3Path = evaluation.s3Path;

    await this.documentsService
      .getDocumentPreSignedURL(document)
      .then(async (): Promise<void> => {
        this.pdfSource = this.documentsService.documentPreSignedURL;
        await this.modalsService.showModal(
          <TemplateRef<any>>this.documentViewer,
          true
        );
      });
  }
  pageRendered(): void {
    console.log('Documento cargado.');
    this.isGettingURL = false;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el estado de la evaluación.
   * @param {string} status Estado de la evaluación.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.rtcEvaluationsService.getImageSrc(status);
  }
  dwonloadExcel() {
    let year_month = new Date().toISOString().slice(0, 7);
    this.apiService.downloadExcelRTCHistoryFile(this.business, year_month);
  }
  cleanFilters() {
    this.isGettingEvaluations = true;
    this.filteredEvaluations = [];
    this.listEvaluations = [];
    this.uniqueFormIds = [];
    this.filterForm = this.fb.group({
      dateRangeStart: [''],
      dateRangeEnd: [''],
      plate: [''],
      carrier: [''],
      center: [''],
      result: [''],
      sapID: [''],
    });
    this.getEvaluations();
  }
}
