import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { appConstants } from '../../shared/constants/constants';

import {
  APIService,
  Driver,
  GetDriverQuery,
  ListDriversQuery,
  ModelDriverFilterInput,
  ModelSubscriptionDriverFilterInput,
  ModelSubscriptionIDInput,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { filterInputs } from '../../shared/types/filter-inputs';

@Injectable({
  providedIn: 'root',
})
export class DriversService implements OnDestroy {
  driversChanged = new Subject<Driver[]>();
  selectedDriverChanged = new Subject<Driver>();

  private readonly initialDriver: Driver;
  private drivers: Driver[] = [];
  private driversFilter: ModelDriverFilterInput = {};
  private driversFilterForSubscriptions: ModelSubscriptionDriverFilterInput =
    {};
  private selectedDriver: Driver;
  private selectedDriverFilterForSubscriptions: ModelDriverFilterInput = {};
  private businessSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService
  ) {
    this.initialDriver = {
      ...appConstants.driver.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedDriver = this.initialDriver;
    this.setDriversFilter();
    this.setSelectedDriverFilterForSubscriptions();
    // El servicio de conductores inicia con el header, por
    // lo que debemos actualizar los filtros ante cambios de negocio.
    this.businessSubscription = this.usersService.business.subscribe(
      (): void => {
        this.setDriversFilter();
      }
    );
  }

  // ---------------------------------
  // Métodos para el conductor default
  // ---------------------------------
  /**
   * Retorna una estructura de conductor vacía para iniciar
   * un objeto de tipo Driver.
   * @return {Driver}
   */
  getInitialDriver(): Driver {
    return { ...this.initialDriver };
  }

  // --------------------------------------
  // Métodos para el conductor seleccionado
  // --------------------------------------
  /**
   * Configura el conductor seleccionado para referencia de
   * todos los componentes.
   * @param {Driver} driver Conductor seleccionado.
   */
  setSelectedDriver(driver: Driver): void {
    this.selectedDriver = driver;
    this.setSelectedDriverFilterForSubscriptions();
    this.selectedDriverChanged.next({ ...this.selectedDriver });
    console.log('selectedDriver', this.selectedDriver);

    // Documentos
    this.documentsService.setId('driverId', this.selectedDriver.driverId);
    this.documentsService.setSelectedModel('DRIVER');
    this.documentsService.setSelectedCenter(driver.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del conductor actualizados.'));
  }

  /**
   * Actualiza los datos del conductor seleccionado.
   * @return {Promise}
   */
  async refreshSelectedDriver(): Promise<void> {
    const getDriverResult: GetDriverQuery = await this.api.GetDriver(
      this.selectedDriver.driverId
    );
    this.setSelectedDriver(<Driver>getDriverResult);
  }

  /**
   * Retorna el conductor seleccionado.
   * @return {Driver}
   */
  getSelectedDriver(): Driver {
    return { ...this.selectedDriver };
  }

  // ------------------------------------
  // Métodos para la lista de Conductores
  // ------------------------------------
  /**
   * Configura la lista de conductores para referencia de
   * todos los componentes.
   * @param {Driver[]} drivers Lista de conductores.
   */
  setDrivers(drivers: Driver[]): void {
    this.drivers = drivers;
    this.setDriversFilter();
    this.driversChanged.next(this.drivers.slice());
  }

  /**
   * Actualiza la lista de conductores.
   * @return {Promise}
   */
  async refreshDrivers(): Promise<void> {
    let listDriversResult: ListDriversQuery = await this.api.ListDrivers(
      '',
      this.getDriversFilter()
    );
    let tempListDrivers = <Driver[]>listDriversResult.items;
    let nextToken = listDriversResult.nextToken;
    // Es posible que la primera query no retorne todos los conductores.
    while (nextToken) {
      let loopListDriversResult: ListDriversQuery = await this.api.ListDrivers(
        '',
        this.getDriversFilter(),
        100,
        nextToken
      );
      tempListDrivers.push(...(<Driver[]>loopListDriversResult.items));
      nextToken = loopListDriversResult.nextToken;
    }

    this.setDrivers(tempListDrivers.slice());
  }

  /**
   * Retorna la lista de conductores.
   * @return {Driver[]}
   */
  getDrivers(): Driver[] {
    return this.drivers.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de conductores y subscripciones.
   * @private
   */
  private setDriversFilter(): void {
    let modelFilterInput: filterInputs =
      this.usersService.getEntitiesFilter(true);

    this.driversFilter = modelFilterInput;
    this.driversFilterForSubscriptions = modelFilterInput;
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de conductores.
   * @return {ModelDriverFilterInput}
   */
  getDriversFilter(): ModelDriverFilterInput {
    return { ...this.driversFilter };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de conductores.
   * @return {ModelSubscriptionDriverFilterInput}
   */
  getDriversFilterForSubscriptions(): ModelSubscriptionDriverFilterInput {
    return { ...this.driversFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del conductor seleccionado.
   */
  private setSelectedDriverFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedDriver.driverId,
    };
    this.selectedDriverFilterForSubscriptions = {
      driverId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del conductor seleccionado.
   * @return {ModelSubscriptionDriverFilterInput}
   */
  getSelectedDriverFilterForSubscriptions(): ModelSubscriptionDriverFilterInput {
    return { ...this.selectedDriverFilterForSubscriptions };
  }

  ngOnDestroy(): void {
    this.businessSubscription.unsubscribe();
    console.log('driver.service subscriptions removed.');
  }
}
