<div class="row align-items-end" *ngIf="!isUploadingDocuments">
  <div class="col-sm-6 text-center" *ngIf="!isGettingVehicle">
    <div>
      <small id="vehicle-dates" class="text-muted">
        Creado: {{ vehicle.createdAt | dateFormat | dateWithoutSeconds }} <br />
        Actualizado: {{ vehicle.updatedAt | dateFormat | dateWithoutSeconds }}
      </small>
    </div>
    <div class="btn-group">
      <button
        id="vehicle-details-approve"
        type="button"
        class="btnApprove"
        (click)="onApproveVehicle()"
        *ngIf="vehicleNeedsApproval && showButton('approve')"
      >
        Aprobar
      </button>
      <button
        id="vehicle-details-reject"
        type="button"
        class="btnDecline"
        (click)="onRejectVehicle()"
        *ngIf="vehicleCanBeRejected && showButton('reject')"
      >
        Rechazar
      </button>
    </div>
  </div>
  <div class="col-sm-6 text-end">
    <div class="btn-group" style="padding-top: 60px">
      <button
        id="vehicle-details-edit"
        type="button"
        class="btnApprove"
        *ngIf="!isGettingVehicle && showButton('edit')"
        (click)="onEditVehicle()"
      >
        Editar
      </button>
      <button
        id="vehicle-details-delete"
        type="button"
        class="btnDecline"
        (click)="onDeleteVehicle()"
        *ngIf="!isGettingVehicle && showButton('delete')"
      >
        Borrar
      </button>
      <button
        id="vehicle-details-refresh"
        class="btn btn-outline-secondary"
        style="border-radius: 30px"
        *ngIf="!isGettingVehicle"
        (click)="onRefreshVehicle()"
      >
        <span>
          <img
            ngSrc="assets/images/icons/actualizar.svg"
            height="25"
            width="25"
            alt="Actualizar"
          />
        </span>
      </button>
    </div>
  </div>
</div>
<div class="row mt-3" *ngIf="!isUploadingDocuments">
  <div class="col-sm-6 text-center my-5" *ngIf="isGettingVehicle">
    <app-spinner message="Cargando detalles del vehículo..."></app-spinner>
  </div>
  <div
    class="col-sm-6 box-details-cab"
    [ngStyle]="{ border: '3px solid ' + getColor() }"
    *ngIf="!isGettingVehicle"
  >
    <div class="div-box-sap-id">
      <h4 [ngStyle]="{ color: getColor(), 'font-weight': 'bold' }">
        SAP ID: {{ vehicle.sapId! | sapId }}
      </h4>
    </div>
    <div style="display: inline">
      <p><strong>Transportista </strong>: <br />{{ vehicle.carrier!.name }}</p>
      <p>
        <strong>Patente</strong>: <br />{{ vehicle.licensePlate }}
        <span *ngIf="hasTrailer"
          >( Semirremolque: {{ vehicle.vehicleTrailerTrailerId }} )</span
        >
      </p>
      <div class="divCenter" style="text-align: center">
        <p *ngIf="showBlockingMotives">
          <strong
            >Bloqueado el
            {{
              vehicle.lastStatusInformedAt || vehicle.updatedAt
                | dateFormat
                | dateWithoutSeconds
            }}
            por</strong
          >:<br />
          <span
            class="badge border rounded-4 text-bg-light mx-1"
            *ngFor="let motive of vehicle.blockingMotives"
          >
            {{ motive }}
          </span>
        </p>
      </div>
    </div>
    <div class="divCenter">
      <h5>
        <span
          class="badge"
          [ngStyle]="{ 'background-color': getColor() }"
          style="width: 200px; border-radius: 60px"
        >
          <span>
            <img
              [src]="getImageSrc(vehicle.status)"
              height="25"
              width="25"
              alt="Estado"
            />
          </span>
          {{ vehicle.status | master : "VEHICLES#STATUSES" }}</span
        >
      </h5>
      <div *ngIf="hasComment">
        <label
          class="custom-file-upload pt-2 mx-1 float-end text-secondary"
          style="cursor: pointer"
        >
          <a id="entity-comment" title="Comentario" (click)="onViewComment()">
            <span class="material-symbols-outlined">comment</span>
          </a>
        </label>
      </div>
    </div>
    <div class="divCenter mb-2" *ngIf="hasComment && showComment">
      <div class="collapse comment" [ngClass]="showCommentClass()">
        <div class="card card-body">
          {{ vehicle.comment }}
        </div>
      </div>
    </div>
    <div class="divCenter">
      <h6>
        <span
          class="badge border rounded-4 text-bg-light"
          *ngIf="hasToExpireDocuments()"
        >
          <span class="badge border rounded-circle text-bg-light">{{
            vehicle.documentsToExpire
          }}</span>
          por vencer</span
        >
      </h6>
      <h6>
        <span
          class="badge border rounded-4 text-bg-light"
          *ngIf="hasExpiredDocuments()"
        >
          <span class="badge border rounded-circle text-bg-light">{{
            vehicle.documentsExpired
          }}</span>
          vencido</span
        >
      </h6>
    </div>
    <div ngbAccordion style="padding: 10px">
      <div ngbAccordionItem [collapsed]="true">
        <h2 ngbAccordionHeader>
          <button id="vehicle-details-accordion" ngbAccordionButton>
            Ver detalles
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="table-container">
            <ng-template>
              <table class="table table-hover">
                <caption>
                  Detalles del Vehículo
                </caption>
                <tbody>
                  <tr>
                    <th scope="row">Centro</th>
                    <td>{{ vehicle.originalCenter! | master : "CENTERS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Marca</th>
                    <td>{{ vehicle.brand | master : "VEHICLES#BRANDS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Modelo</th>
                    <td>{{ vehicle.model }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Año de Fabricación</th>
                    <td>{{ vehicle.year }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Antigüedad</th>
                    <td
                      [ngClass]="getVehicleAgeClass(currentYear - vehicle.year)"
                    >
                      {{ currentYear - vehicle.year }} año{{
                        currentYear - vehicle.year === 1 ? "" : "s"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Color</th>
                    <td>{{ vehicle.color | master : "VEHICLES#COLORS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo</th>
                    <td>{{ vehicle.type | master : "VEHICLES#TYPES" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Capacidad</th>
                    <td>
                      {{ vehicle.capacity | master : "VEHICLES#CAPACITIES" }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Es Spot</th>
                    <td>{{ vehicle.isSpot ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">GPS IMEI</th>
                    <td>{{ vehicle.imei }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Cámara IMEI</th>
                    <td>{{ vehicle.cameraImei }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Centro de Apoyo</th>
                    <td>{{ vehicle.center | master : "CENTERS" }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <app-documents></app-documents>
  </div>
</div>
<div class="text-center my-5" *ngIf="isUploadingDocuments">
  <app-spinner
    message="Subiendo documentos a la nube, por favor espere"
  ></app-spinner>
</div>

<!-- Modals -->
<ng-template #vehicleModal let-c="close" let-d="dismiss">
  <app-modal-with-comment
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [showCommentForm]="modalWithComment"
    [mandatoryComment]="isBeingRejected"
    [commentForm]="commentForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-with-comment>
</ng-template>
