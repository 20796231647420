import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  APIService,
  Semirremolque,
  GetSemirremolqueQuery,
  ListSemirremolquesQuery,
  ModelSemirremolqueFilterInput,
  ModelSubscriptionIDInput,
  ModelSubscriptionSemirremolqueFilterInput,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { appConstants } from '../../shared/constants/constants';
import { filterInputs } from '../../shared/types/filter-inputs';

@Injectable({
  providedIn: 'root',
})
export class SemirremolquesService {
  selectedSemirremolqueChanged = new Subject<Semirremolque>();
  semirremolquesChanged = new Subject<Semirremolque[]>();
  decoupledSemirremolquesChanged = new Subject<Semirremolque[]>();

  private readonly initialSemirremolque: Semirremolque;
  private selectedSemirremolque: Semirremolque;
  private semirremolques: Semirremolque[] = [];
  private decoupledSemirremolques: Semirremolque[] = [];
  private semirremolquesFilter: ModelSemirremolqueFilterInput = {};
  private semirremolquesFilterForSubscriptions: ModelSubscriptionSemirremolqueFilterInput =
    {};
  private decoupledSemirremolquesFilter: ModelSemirremolqueFilterInput = {};
  private decoupledSemirremolquesFilterForSubscriptions: ModelSubscriptionSemirremolqueFilterInput =
    {};
  private selectedSemirremolqueFilterForSubscriptions: ModelSubscriptionSemirremolqueFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService
  ) {
    this.initialSemirremolque = {
      ...appConstants.semirremolque.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedSemirremolque = this.initialSemirremolque;
    this.setSemirremolquesFilter();
  }

  // ---------------------------------------
  // Métodos para el semirremolque default
  // ---------------------------------------
  /**
   * Retorna una estructura de semirremolque vacía para iniciar
   * un objeto de tipo Semirremolque.
   * @return {Semirremolque}
   */
  getInitialSemirremolque(): Semirremolque {
    return { ...this.initialSemirremolque };
  }

  // --------------------------------------------
  // Métodos para el semirremolque seleccionado
  // --------------------------------------------
  /**
   * Configura el semirremolque seleccionado para referencia de
   * todos los componentes.
   * @param {Semirremolque} semirremolque Semirremolque seleccionado.
   */
  setSelectedSemirremolque(semirremolque: Semirremolque): void {
    this.selectedSemirremolque = semirremolque;
    this.setSelectedSemirremolqueFilterForSubscriptions();
    this.selectedSemirremolqueChanged.next({ ...this.selectedSemirremolque });
    console.log('selectedSemirremolque', this.selectedSemirremolque);

    // Documentos
    this.documentsService.setId(
      'semirremolqueId',
      this.selectedSemirremolque.semirremolqueId
    );
    this.documentsService.setSelectedModel('SEMIRREMOLQUE');
    this.documentsService.setSelectedCenter(semirremolque.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del semirremolque actualizados.'));
  }

  /**
   * Actualiza los datos del semirremolque seleccionado.
   * @return {Promise}
   */
  async refreshSelectedSemirremolque(): Promise<void> {
    const getSemirremolqueResult: GetSemirremolqueQuery =
      await this.api.GetSemirremolque(
        this.selectedSemirremolque.semirremolqueId
      );
    this.setSelectedSemirremolque(<Semirremolque>getSemirremolqueResult);
  }

  /**
   * Retorna el semirremolque seleccionado.
   * @return {Semirremolque}
   */
  getSelectedSemirremolque(): Semirremolque {
    return { ...this.selectedSemirremolque };
  }

  // ---------------------------------------
  // Métodos para la lista de Semirremolques
  // ---------------------------------------
  /**
   * Configura la lista de semirremolques para referencia de
   * todos los componentes.
   * @param {Semirremolque[]} semirremolques Lista de semirremolques.
   */
  setSemirremolques(semirremolques: Semirremolque[]): void {
    this.semirremolques = semirremolques;
    this.setSemirremolquesFilter();
    this.semirremolquesChanged.next(this.semirremolques.slice());
  }

  /**
   * Actualiza la lista de semirremolques.
   * @return {Promise}
   */
  async refreshSemirremolques(): Promise<void> {
    let listSemirremolquesResult: ListSemirremolquesQuery =
      await this.api.ListSemirremolques('', this.getSemirremolquesFilter());
    let tempListSemirremolques = <Semirremolque[]>(
      listSemirremolquesResult.items
    );
    let nextToken = listSemirremolquesResult.nextToken;
    // Es posible que la primera query no retorne todos los semirremolques.
    while (nextToken) {
      let loopListSemirremolquesResult: ListSemirremolquesQuery =
        await this.api.ListSemirremolques(
          '',
          this.getSemirremolquesFilter(),
          100,
          nextToken
        );
      tempListSemirremolques.push(
        ...(<Semirremolque[]>loopListSemirremolquesResult.items)
      );
      nextToken = loopListSemirremolquesResult.nextToken;
    }

    this.setSemirremolques(tempListSemirremolques.slice());
  }

  /**
   * Retorna la lista de semirremolques.
   * @return {Semirremolque[]}
   */
  getSemirremolques(): Semirremolque[] {
    return this.semirremolques.slice();
  }

  /**
   * Configura la lista de semirremolques desacoplados para referencia de
   * todos los componentes.
   * @param {Semirremolque[]} semirremolques Lista de semirremolques acoplados.
   */
  setDecoupledSemirremolques(semirremolques: Semirremolque[]): void {
    this.decoupledSemirremolques = semirremolques;
    this.setSemirremolquesFilter();
    this.decoupledSemirremolquesChanged.next(
      this.decoupledSemirremolques.slice()
    );
  }

  /**
   * Actualiza la lista de semirremolques desacoplados.
   * @return {Promise}
   */
  async refreshDecoupledSemirremolques(): Promise<void> {
    let listSemirremolquesResult: ListSemirremolquesQuery =
      await this.api.ListSemirremolques(
        '',
        this.getDecoupledSemirremolquesFilter()
      );
    let tempListDecoupledSemirremolques = <Semirremolque[]>(
      listSemirremolquesResult.items
    );
    let nextToken = listSemirremolquesResult.nextToken;
    // Es posible que la primera query no retorne todos los semirremolques.
    while (nextToken) {
      let loopListSemirremolquesResult: ListSemirremolquesQuery =
        await this.api.ListSemirremolques(
          '',
          this.getDecoupledSemirremolquesFilter(),
          100,
          nextToken
        );
      tempListDecoupledSemirremolques.push(
        ...(<Semirremolque[]>loopListSemirremolquesResult.items)
      );
      nextToken = loopListSemirremolquesResult.nextToken;
    }

    this.setDecoupledSemirremolques(tempListDecoupledSemirremolques.slice());
  }

  /**
   * Retorna la lista de semirremolques desacoplados.
   * @return {Semirremolque[]}
   */
  getDecoupledSemirremolques(): Semirremolque[] {
    return this.decoupledSemirremolques.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura los filtros para las consultas
   * de listas de semirremolques y subscripciones.
   * @private
   */
  private setSemirremolquesFilter(): void {
    const business: string = this.usersService.business.value.toUpperCase();
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();
    const decoupledFixedFilters:
      | ModelSemirremolqueFilterInput
      | ModelSubscriptionSemirremolqueFilterInput = {
      sapId: { ne: 'Sin Asignar' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      semirremolqueCoupleCoupleId: { notContains: '#' },
    };

    this.semirremolquesFilter = <ModelSemirremolqueFilterInput>modelFilterInput;
    this.semirremolquesFilterForSubscriptions = <
      ModelSubscriptionSemirremolqueFilterInput
    >modelFilterInput;
    this.decoupledSemirremolquesFilter = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
    this.decoupledSemirremolquesFilterForSubscriptions = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de semirremolques.
   * @return {ModelSemirremolqueFilterInput}
   */
  getSemirremolquesFilter(): ModelSemirremolqueFilterInput {
    return { ...this.semirremolquesFilter };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de semirremolques.
   * @return {ModelSubscriptionSemirremolqueFilterInput}
   */
  getSemirremolquesFilterForSubscriptions(): ModelSubscriptionSemirremolqueFilterInput {
    return { ...this.semirremolquesFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del semirremolque seleccionado.
   */
  private setSelectedSemirremolqueFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedSemirremolque.semirremolqueId,
    };
    this.selectedSemirremolqueFilterForSubscriptions = {
      semirremolqueId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del semirremolque seleccionado.
   * @return {ModelSubscriptionSemirremolqueFilterInput}
   */
  getSelectedSemirremolqueFilterForSubscriptions(): ModelSubscriptionSemirremolqueFilterInput {
    return { ...this.selectedSemirremolqueFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de semirremolques desacoplados.
   * @return {ModelSubscriptionSemirremolqueFilterInput}
   */
  getDecoupledSemirremolquesFilterForSubscriptions(): ModelSubscriptionSemirremolqueFilterInput {
    return { ...this.decoupledSemirremolquesFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para subscripciones
   * de listas de semirremolques desacoplados.
   * @return {ModelSemirremolqueFilterInput}
   */
  getDecoupledSemirremolquesFilter(): ModelSemirremolqueFilterInput {
    return { ...this.decoupledSemirremolquesFilter };
  }
}
