import { Injectable } from '@angular/core';
import {
  APIService,
  Couple,
  ListCouplesQuery,
  ModelCoupleFilterInput,
  ModelSubscriptionCoupleFilterInput,
} from '../../app-sync.service';
import { Subject } from 'rxjs';
import { UsersService } from '../users/users.service';
import { filterInputs } from '../../shared/types/filter-inputs';

@Injectable({
  providedIn: 'root',
})
export class CouplingsService {
  couplesChanged = new Subject<Couple[]>();

  private couples: Couple[] = [];
  private couplesFilter: ModelCoupleFilterInput = {};
  private couplesFilterForSubscriptions: ModelSubscriptionCoupleFilterInput =
    {};
  constructor(private api: APIService, private usersService: UsersService) {
    this.setCouplesFilter();
  }

  // --------------------------------------
  // Métodos para la lista de Acoplamientos
  // --------------------------------------

  /**
   * Configura la lista de acoplamientos para referencia de
   * todos los componentes.
   * @param {Couple[]} couples Lista de acoplamientos.
   */
  setCouples(couples: Couple[]): void {
    this.couples = couples;
    this.setCouplesFilter();
    this.couplesChanged.next(this.couples.slice());
  }

  /**
   * Actualiza la lista de acoplamientos.
   * @return {Promise}
   */
  async refreshCouples(): Promise<void> {
    let listCouplesResult: ListCouplesQuery = await this.api.ListCouples(
      '',
      this.getCouplesFilter()
    );
    let tempListCouples = <Couple[]>listCouplesResult.items;
    let nextToken = listCouplesResult.nextToken;
    // Es posible que la primera query no retorne todos los acoplamientos.
    while (nextToken) {
      let loopListCouplesResult: ListCouplesQuery = await this.api.ListCouples(
        '',
        this.getCouplesFilter(),
        100,
        nextToken
      );
      tempListCouples.push(...(<Couple[]>loopListCouplesResult.items));
      nextToken = loopListCouplesResult.nextToken;
    }

    this.setCouples(tempListCouples.slice());
  }

  /**
   * Retorna la lista de acoplamientos.
   * @return {Couple[]}
   */
  getCouples(): Couple[] {
    return this.couples.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de acoplamientos.
   * @private
   */
  private setCouplesFilter(): void {
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();

    this.couplesFilter = <ModelCoupleFilterInput>modelFilterInput;
    this.couplesFilterForSubscriptions = <ModelSubscriptionCoupleFilterInput>(
      modelFilterInput
    );
  }

  /**
   * Retorna el filtro para consultas
   * de listas de acoplamientos.
   * @return {ModelCoupleFilterInput}
   */
  getCouplesFilter(): ModelCoupleFilterInput {
    return { ...this.couplesFilter };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de acoplamientos.
   * @return {ModelSubscriptionCoupleFilterInput}
   */
  getCouplesFilterForSubscriptions(): ModelSubscriptionCoupleFilterInput {
    return { ...this.couplesFilterForSubscriptions };
  }
}
