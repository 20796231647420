<div class="row">
  <div class="text-center my-5" *ngIf="isGettingVehicle">
    <app-spinner
      [message]="
        editMode ? 'Cargando detalles del vehículo' : 'Cargando formulario'
      "
    ></app-spinner>
  </div>
  <div class="col-xs-12" *ngIf="!isGettingVehicle">
    <h3>{{ editMode ? "Editar" : "Nuevo" }} Vehículo</h3>
    <form [formGroup]="vehicleForm" (ngSubmit)="onSubmit()">
      <div class="container-fluid">
        <h4>
          Transportista
          <span *ngIf="!editMode" class="text-danger fs-4">*</span>
        </h4>
        <div class="row">
          <div class="col-sm">
            <div class="form-group">
              <select
                *ngIf="isCarrier"
                id="vehicle-carrier"
                formControlName="carrier"
                class="form-select"
              >
                <option [value]="carrierValueId()" selected>
                  {{ carrierValueToDisplay() }}
                </option>
              </select>
              <select
                *ngIf="isAdmin"
                id="carrier"
                formControlName="carrier"
                class="form-select"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let carrier of master.CARRIERS"
                  [ngValue]="carrier.valueId"
                >
                  {{ carrier.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="container-fluid">
        <h4>Vehículo</h4>
        <div class="row">
          <div class="col-sm">
            <label for="licensePlate" class="form-label"
              >Patente
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="licensePlate"
                formControlName="licensePlate"
                class="form-control immutable-field"
                placeholder="Ejemplo: AABB00"
                appInputUppercase
              />
            </div>
          </div>
          <div class="col-sm">
            <label for="center" class="form-label"
              >Centro
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="center"
                formControlName="center"
                class="form-select"
                (change)="onVehicleCenterChange()"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let center of master.CENTERS"
                  [ngValue]="center.valueId"
                >
                  {{ center.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm">
            <label for="brand" class="form-label"
              >Marca
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select id="brand" formControlName="brand" class="form-select">
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let brand of master['VEHICLES#BRANDS']"
                  [ngValue]="brand.valueId"
                >
                  {{ brand.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm">
            <label for="model" class="form-label"
              >Modelo
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="model"
                formControlName="model"
                class="form-control immutable-field"
                placeholder="Ejemplo: Volvo FH"
                appInputTrim
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm">
            <label for="year" class="form-label"
              >Año de Fabricación
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <input
                type="number"
                id="year"
                formControlName="year"
                class="form-control immutable-field"
                placeholder="Ejemplo: 2020"
                (change)="onVehicleYearChange()"
              />
            </div>
          </div>
          <div class="col-sm">
            <label for="color" class="form-label"
              >Color
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select id="color" formControlName="color" class="form-select">
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let color of master['VEHICLES#COLORS']"
                  [ngValue]="color.valueId"
                >
                  {{ color.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm">
            <label for="type" class="form-label"
              >Tipo
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="type"
                formControlName="type"
                class="form-select"
                (change)="onVehicleTypeChange()"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let type of master['VEHICLES#TYPES']"
                  [ngValue]="type.valueId"
                >
                  {{ type.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm">
            <label for="capacity" class="form-label"
              >Capacidad
              <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
            >
            <div class="form-group">
              <select
                id="capacity"
                formControlName="capacity"
                class="form-select"
              >
                <option value="" selected>Seleccionar...</option>
                <option
                  *ngFor="let capacity of master['VEHICLES#CAPACITIES']"
                  [ngValue]="capacity.valueId"
                >
                  {{ capacity.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-2">
          <div class="col-sm text-center border rounded mx-1">
            <p class="pt-1">
              Es Spot <span *ngIf="!editMode" class="text-danger fs-4">*</span>
            </p>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="isSpotYes"
                formControlName="isSpot"
                class="form-check-input"
                value="true"
              />
              <label for="isSpotYes" class="form-check-label">Sí</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="isSpotNo"
                formControlName="isSpot"
                class="form-check-input"
                value="false"
              />
              <label for="isSpotNo" class="form-check-label">No</label>
            </div>
          </div>
          <div class="col-sm">
            <label for="imei" class="form-label">GPS IMEI</label>
            <div class="form-group">
              <input
                type="text"
                id="imei"
                formControlName="imei"
                class="form-control immutable-field"
                placeholder="Ejemplo: 123456789012345"
                appInputTrim
              />
            </div>
          </div>
          <div class="col-sm">
            <label for="cameraImei" class="form-label">Cámara IMEI</label>
            <div class="form-group">
              <input
                type="text"
                id="cameraImei"
                formControlName="cameraImei"
                class="form-control immutable-field"
                placeholder="Ejemplo: 123456789012345"
                appInputTrim
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="container-fluid">
        <h5>
          <i class="material-symbols-outlined align-middle">description</i>
          Documentos del Vehículo
        </h5>
        <div class="alert alert-warning" role="alert">
          <i class="material-symbols-outlined align-middle">warning</i> Solo se
          aceptan documentos en formato PDF.
        </div>
        <div class="col-xs-12" formArrayName="documents">
          <div
            class="row border-top"
            id="vehicle-documents"
            *ngFor="let documentControl of documentsControl; let i = index"
            [formGroupName]="i"
          >
            <div class="row my-2">
              <div class="col-sm-11">
                <input
                  type="text"
                  id="documentName"
                  class="form-control-plaintext form-control-lg"
                  formControlName="documentName"
                  readonly
                />
              </div>
              <div
                class="col-sm-1"
                *ngIf="
                  !editMode &&
                  isMandatoryDocument(
                    documentControl.get('documentMasterValueId')?.value
                  )
                "
              >
                <p class="text-danger fs-4">*</p>
              </div>
            </div>
            <div
              *ngIf="
                editMode &&
                invalidControl(documentControl.get('documentExpirationDate'))
              "
            >
              <small class="form-text text-danger">
                Documento vencido, es necesario actualizar.
              </small>
            </div>
            <div
              class="row"
              *ngIf="
                !isDatelessDocument(
                  documentControl.get('documentMasterValueId')?.value
                )
              "
            >
              <label for="documentIssueDate" class="col-sm-2 col-form-label"
                >Emisión</label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  id="documentIssueDate"
                  formControlName="documentIssueDate"
                  class="form-control"
                />
                <div
                  *ngIf="showHelper(documentControl.get('documentIssueDate'))"
                >
                  <small class="form-text text-muted">
                    {{
                      helperMessages(documentControl.get("documentIssueDate"))
                    }}
                  </small>
                </div>
              </div>
              <label
                for="documentExpirationDate"
                class="col-sm-2 col-form-label"
                >Vencimiento</label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  id="documentExpirationDate"
                  formControlName="documentExpirationDate"
                  class="form-control"
                />
                <div
                  *ngIf="
                    showHelper(documentControl.get('documentExpirationDate'))
                  "
                >
                  <small class="form-text text-muted">
                    {{
                      helperMessages(
                        documentControl.get("documentExpirationDate")
                      )
                    }}
                  </small>
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-sm-12">
                <input
                  type="file"
                  accept=".pdf"
                  id="documentFile"
                  formControlName="documentFile"
                  class="form-control"
                  (change)="onChangeDocumentFile($event, i)"
                />
                <div *ngIf="showHelper(documentControl.get('documentFile'))">
                  <small class="form-text text-muted">
                    {{ helperMessages(documentControl.get("documentFile")) }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span *ngIf="vehicleHasTrailer">
        <hr />
        <div class="container-fluid">
          <h4>Semirremolque</h4>
          <div class="row my-2">
            <div class="col-sm-6">
              <label for="trailerLicensePlate" class="form-label"
                >Patente
                <span *ngIf="!editMode" class="text-danger fs-4">*</span></label
              >
              <div class="form-group">
                <input
                  type="text"
                  id="trailerLicensePlate"
                  formControlName="trailerLicensePlate"
                  class="form-control"
                  placeholder="Patente, ejemplo: AABB00"
                />
              </div>
            </div>
          </div>
          <h5>
            <i class="material-symbols-outlined align-middle">description</i>
            Documentos del Semirremolque
          </h5>
          <div class="col-xs-12" formArrayName="trailerDocuments">
            <div
              class="row border-top"
              id="vehicle-trailer"
              *ngFor="let trailerControl of trailersControl; let i = index"
              [formGroupName]="i"
            >
              <div class="row my-2">
                <div class="col-sm-11">
                  <input
                    type="text"
                    id="trailerDocumentName"
                    class="form-control-plaintext form-control-lg"
                    formControlName="trailerDocumentName"
                    readonly
                  />
                </div>
                <div
                  class="col-sm-1"
                  *ngIf="
                    !editMode &&
                    isMandatoryTrailerDocument(
                      trailerControl.get('trailerDocumentMasterValueId')?.value
                    )
                  "
                >
                  <p class="text-danger fs-4">*</p>
                </div>
              </div>
              <div
                *ngIf="
                  editMode &&
                  invalidControl(
                    trailerControl.get('trailerDocumentExpirationDate')
                  )
                "
              >
                <small class="form-text text-danger">
                  Documento vencido, es necesario actualizar.
                </small>
              </div>
              <div class="row">
                <label
                  for="trailerDocumentIssueDate"
                  class="col-sm-2 col-form-label"
                  >Emisión</label
                >
                <div class="col-sm-4">
                  <input
                    type="date"
                    id="trailerDocumentIssueDate"
                    formControlName="trailerDocumentIssueDate"
                    class="form-control"
                  />
                  <div
                    *ngIf="
                      showHelper(trailerControl.get('trailerDocumentIssueDate'))
                    "
                  >
                    <small class="form-text text-muted">
                      {{
                        helperMessages(
                          trailerControl.get("trailerDocumentIssueDate")
                        )
                      }}
                    </small>
                  </div>
                </div>
                <label
                  for="trailerDocumentExpirationDate"
                  class="col-sm-2 col-form-label"
                  >Vencimiento</label
                >
                <div class="col-sm-4">
                  <input
                    type="date"
                    id="trailerDocumentExpirationDate"
                    formControlName="trailerDocumentExpirationDate"
                    class="form-control"
                  />
                  <div
                    *ngIf="
                      showHelper(
                        trailerControl.get('trailerDocumentExpirationDate')
                      )
                    "
                  >
                    <small class="form-text text-muted">
                      {{
                        helperMessages(
                          trailerControl.get("trailerDocumentExpirationDate")
                        )
                      }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-sm-12">
                  <input
                    type="file"
                    accept=".pdf"
                    id="trailerDocumentFile"
                    formControlName="trailerDocumentFile"
                    class="form-control"
                    (change)="onChangeTrailerDocumentFile($event, i)"
                  />
                  <div
                    *ngIf="
                      showHelper(trailerControl.get('trailerDocumentFile'))
                    "
                  >
                    <small class="form-text text-muted">
                      {{
                        helperMessages(
                          trailerControl.get("trailerDocumentFile")
                        )
                      }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
      <hr />
      <div class="row">
        <div class="col-xs-12">
          <button
            id="vehicle-edit-cancel"
            type="button"
            class="btn btn-outline-danger"
            (click)="onCancel()"
          >
            Cancelar
          </button>
          <button
            id="vehicle-edit-show"
            type="button"
            class="btn btn-outline-secondary mx-2"
            (click)="showForm()"
            *ngIf="isDev"
          >
            Log Form
          </button>
          <button
            id="vehicle-edit-submit"
            type="submit"
            class="btn btn-outline-primary mx-2 float-end"
            [disabled]="!vehicleForm.valid"
          >
            {{ editMode ? "Guardar" : "Crear Vehículo" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
