import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  APIService,
  Tracto,
  GetTractoQuery,
  ListTractosQuery,
  ModelTractoFilterInput,
  ModelSubscriptionIDInput,
  ModelSubscriptionTractoFilterInput,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { appConstants } from '../../shared/constants/constants';
import { filterInputs } from '../../shared/types/filter-inputs';

@Injectable({
  providedIn: 'root',
})
export class TractosService {
  selectedTractoChanged = new Subject<Tracto>();
  tractosChanged = new Subject<Tracto[]>();
  decoupledTractosChanged = new Subject<Tracto[]>();

  private readonly initialTracto: Tracto;
  private selectedTracto: Tracto;
  private tractos: Tracto[] = [];
  private decoupledTractos: Tracto[] = [];
  private tractosFilter: ModelTractoFilterInput = {};
  private tractosFilterForSubscriptions: ModelSubscriptionTractoFilterInput =
    {};
  private decoupledTractosFilter: ModelTractoFilterInput = {};
  private decoupledTractosFilterForSubscriptions: ModelSubscriptionTractoFilterInput =
    {};
  private selectedTractoFilterForSubscriptions: ModelTractoFilterInput = {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService
  ) {
    this.initialTracto = {
      ...appConstants.tracto.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedTracto = this.initialTracto;
    this.setTractosFilter();
  }

  // ---------------------------------------
  // Métodos para el tracto default
  // ---------------------------------------
  /**
   * Retorna una estructura de tracto vacía para iniciar
   * un objeto de tipoTracto.
   * @return {Tracto}
   */
  getInitialTracto(): Tracto {
    return { ...this.initialTracto };
  }

  // --------------------------------------------
  // Métodos para el tracto seleccionado
  // --------------------------------------------
  /**
   * Configura el tracto seleccionado para referencia de
   * todos los componentes.
   * @param {Tracto} tracto Tracto seleccionado.
   */
  setSelectedTracto(tracto: Tracto): void {
    this.selectedTracto = tracto;
    this.setSelectedTractoFilterForSubscriptions();
    this.selectedTractoChanged.next({ ...this.selectedTracto });
    console.log('selectedTracto', this.selectedTracto);

    // Documentos
    this.documentsService.setId('tractoId', this.selectedTracto.tractoId);
    this.documentsService.setSelectedModel('TRACTO');
    this.documentsService.setSelectedCenter(tracto.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del tracto actualizados.'));
  }

  /**
   * Actualiza los datos del tracto seleccionado.
   * @return {Promise}
   */
  async refreshSelectedTracto(): Promise<void> {
    const getTractoResult: GetTractoQuery = await this.api.GetTracto(
      this.selectedTracto.tractoId
    );
    this.setSelectedTracto(<Tracto>getTractoResult);
  }

  /**
   * Retorna el tracto seleccionado.
   * @return {Tracto}
   */
  getSelectedTracto(): Tracto {
    return { ...this.selectedTracto };
  }

  // --------------------------------
  // Métodos para la lista de Tractos
  // ---------------------------------
  /**
   * Configura la lista de tractos para referencia de
   * todos los componentes.
   * @param {Tracto[]} tractos Lista de tractos.
   */
  setTractos(tractos: Tracto[]): void {
    this.tractos = tractos;
    this.setTractosFilter();
    this.tractosChanged.next(this.tractos.slice());
  }

  /**
   * Actualiza la lista de tractos.
   * @return {Promise}
   */
  async refreshTractos(): Promise<void> {
    let listTractosResult: ListTractosQuery = await this.api.ListTractos(
      '',
      this.getTractosFilter()
    );
    let tempListTractos = <Tracto[]>listTractosResult.items;
    let nextToken = listTractosResult.nextToken;
    // Es posible que la primera query no retorne todos los tractos.
    while (nextToken) {
      let loopListTractosResult: ListTractosQuery = await this.api.ListTractos(
        '',
        this.getTractosFilter(),
        100,
        nextToken
      );
      tempListTractos.push(...(<Tracto[]>loopListTractosResult.items));
      nextToken = loopListTractosResult.nextToken;
    }

    this.setTractos(tempListTractos.slice());
  }

  /**
   * Retorna la lista de tractos.
   * @return {Tracto[]}
   */
  getTractos(): Tracto[] {
    return this.tractos.slice();
  }

  /**
   * Configura la lista de tractos desacoplados para referencia de
   * todos los componentes.
   * @param {Tracto[]} tractos Lista de tractos acoplados.
   */
  setDecoupledTractos(tractos: Tracto[]): void {
    this.decoupledTractos = tractos;
    this.setTractosFilter();
    this.decoupledTractosChanged.next(this.decoupledTractos.slice());
  }

  /**
   * Actualiza la lista de tractos desacoplados.
   * @return {Promise}
   */
  async refreshDecoupledTractos(): Promise<void> {
    let listTractosResult: ListTractosQuery = await this.api.ListTractos(
      '',
      this.getDecoupledTractosFilter()
    );
    let tempListDecoupledTractos = <Tracto[]>listTractosResult.items;
    let nextToken = listTractosResult.nextToken;
    // Es posible que la primera query no retorne todos los tractos.
    while (nextToken) {
      let loopListTractosResult: ListTractosQuery = await this.api.ListTractos(
        '',
        this.getDecoupledTractosFilter(),
        100,
        nextToken
      );
      tempListDecoupledTractos.push(...(<Tracto[]>loopListTractosResult.items));
      nextToken = loopListTractosResult.nextToken;
    }

    this.setDecoupledTractos(tempListDecoupledTractos.slice());
  }

  /**
   * Retorna la lista de tractos desacoplados.
   * @return {Tracto[]}
   */
  getDecoupledTractos(): Tracto[] {
    return this.decoupledTractos.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de tractos y subscripciones.
   * @private
   */
  private setTractosFilter(): void {
    const business: string = this.usersService.business.value.toUpperCase();
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();
    const decoupledFixedFilters:
      | ModelTractoFilterInput
      | ModelSubscriptionTractoFilterInput = {
      tractoCoupleCoupleId: { notContains: '#' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      ...(business !== 'FOREIGN' && { sapId: { ne: 'Sin Asignar' } }),
    };

    this.tractosFilter = <ModelTractoFilterInput>modelFilterInput;
    this.tractosFilterForSubscriptions = <ModelSubscriptionTractoFilterInput>(
      modelFilterInput
    );
    this.decoupledTractosFilter = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
    this.decoupledTractosFilterForSubscriptions = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de tractos.
   * @return {ModelTractoFilterInput}
   */
  getTractosFilter(): ModelTractoFilterInput {
    return { ...this.tractosFilter };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de tractos.
   * @return {ModelSubscriptionTractoFilterInput}
   */
  getTractosFilterForSubscriptions(): ModelSubscriptionTractoFilterInput {
    return { ...this.tractosFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del tracto seleccionado.
   */
  private setSelectedTractoFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedTracto.tractoId,
    };
    this.selectedTractoFilterForSubscriptions = {
      tractoId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del tracto seleccionado.
   * @return {ModelSubscriptionTractoFilterInput}
   */
  getSelectedTractoFilterForSubscriptions(): ModelSubscriptionTractoFilterInput {
    return { ...this.selectedTractoFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de tractos desacoplados.
   * @return {ModelSubscriptionTractoFilterInput}
   */
  getDecoupledTractosFilterForSubscriptions(): ModelSubscriptionTractoFilterInput {
    return { ...this.decoupledTractosFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para subscripciones
   * de listas de tractos desacoplados.
   * @return {ModelTractoFilterInput}
   */
  getDecoupledTractosFilter(): ModelTractoFilterInput {
    return { ...this.decoupledTractosFilter };
  }
}
