import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from 'src/app/shared/constants/constants';

import { APIService, Vehicle } from 'src/app/app-sync.service';
import { VehiclesService } from '../vehicles.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { DataTableDirective } from 'angular-datatables';
import { DocumentsService } from '../../documents/documents.service';
import { MasterService } from '../../master/master.service';
@Component({
  selector: 'app-vehicles-list',
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.css'],
})
export class VehiclesListComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  isGettingVehicles: boolean = false;
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isViewer: boolean = false;
  isCarrier: boolean = false;
  vehicles: Vehicle[];
  vehiclesAttributes: string[] = [
    'SAP ID',
    'Estado',
    'Patente',
    'Transportista',
    'Centro',
    'Alertas',
    'Bloqueos',
  ];

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private vehiclesChangedSubscription: Subscription = new Subscription();
  private onCreateVehicleListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateVehicleListener: ZenObservable.Subscription =
    new Subscription();
  private onDeleteVehicleListener: ZenObservable.Subscription =
    new Subscription();

  constructor(
    private api: APIService,
    private vehiclesService: VehiclesService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private documentService: DocumentsService,
    private masterService: MasterService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.vehicles = this.vehiclesService.getVehicles();
  }

  async ngOnInit(): Promise<void> {
    this.vehiclesChangedSubscription =
      this.vehiclesService.vehiclesChanged.subscribe((vehicles: Vehicle[]) => {
        this.vehicles = vehicles;
      });

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isViewer = this.usersService.isViewer;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateVehiclesList();

    // Una vez cargada la lista de vehículos definimos los filtros para subscripciones
    const subscriptionVehiclesFilter =
      this.vehiclesService.getVehiclesFilterForSubscriptions();
    // Subscripción a Vehículos eliminados
    this.onDeleteVehicleListener = this.api
      .OnDeleteVehicleListener(subscriptionVehiclesFilter)
      .subscribe((response) => {
        if (response) {
          this.vehiclesService.refreshVehicles();
        }
      });
    // Subscripción a Vehículos actualizados
    this.onUpdateVehicleListener = this.api
      .OnUpdateVehicleListener(subscriptionVehiclesFilter)
      .subscribe((response) => {
        if (response) {
          this.vehiclesService.refreshVehicles();
        }
      });
    // Subscripción a Vehículos creados
    this.onCreateVehicleListener = this.api
      .OnCreateVehicleListener(subscriptionVehiclesFilter)
      .subscribe((response) => {
        if (response) {
          this.vehiclesService.refreshVehicles();
        }
      });
  }

  /**
   * Navega a al formulario de creación de Vehículo.
   */
  onNewVehicle(): void {
    this.router
      .navigate(['new'], { relativeTo: this.route })
      .then(() => console.log('navigate to new'));
  }

  /**
   * Llama al método que refresca la lista de Vehículos.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateVehiclesList();
  }

  /**
   * Define el vehículo y el semirremolque que está seleccionado.
   * y navega al detalle de los mismos.
   * @param {Vehicle} vehicle Vehículo seleccionado de la lista.
   */
  onSelectedVehicle(vehicle: Vehicle): void {
    this.documentService.isRefreshFilter.next(true);
    this.vehiclesService.setSelectedVehicle(vehicle);
    if (vehicle.vehicleTrailerTrailerId) {
      this.vehiclesService.setHasTrailer(true);
      this.vehiclesService.setSelectedTrailer(vehicle.trailer!);
    } else {
      this.vehiclesService.setHasTrailer(false);
      this.vehiclesService.releaseSelectedTrailer();
    }
    this.router
      .navigate([vehicle.vehicleId], { relativeTo: this.route })
      .then(() => console.log(`navigate to ${vehicle.vehicleId}`));
  }

  /**
   * Indica si un vehículo tiene documentos por vencer.
   * @param {Vehicle} vehicle Vehículo de la lista.
   * @return {boolean} Boolean que indica si el vehículo tiene documentos por vencer.
   */
  hasToExpireDocuments(vehicle: Vehicle): boolean {
    const docNumber = vehicle.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un vehículo tiene documentos vencidos.
   * @param {Vehicle} vehicle Vehículo de la lista.
   * @return {boolean} Boolean que indica si el vehículo tiene documentos vencidos.
   */
  hasExpiredDocuments(vehicle: Vehicle): boolean {
    const docNumber = vehicle.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del Vehículo.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Refresca la lista de vehículos.
   * @private
   * @return {Promise}
   */
  private async updateVehiclesList(): Promise<void> {
    this.isGettingVehicles = true;
    await this.vehiclesService.refreshVehicles().then((): void => {
      this.isGettingVehicles = false;
    });
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   * @return {boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    if (button === 'create') {
      show = this.isAdmin || this.isCarrier;
    }
    return show;
  }

  /**
   * Determina, con base al rol del usuario, si debe ocultarse una columna de la tabla.
   * @param {string} column Nombre de la columna.
   * @return {Boolean}
   */
  hideColumn(column: string): boolean {
    let hide: boolean = false;

    if (column === 'Transportista') {
      hide = !(this.isAdmin || this.isApprover || this.isViewer);
    }
    return hide;
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  ngOnDestroy(): void {
    this.vehiclesChangedSubscription.unsubscribe();
    this.onDeleteVehicleListener.unsubscribe();
    this.onCreateVehicleListener.unsubscribe();
    this.onUpdateVehicleListener.unsubscribe();
    console.log('vehicles-list.component subscriptions removed.');
  }
}
