import { Injectable } from '@angular/core';
import {
  APIService,
  Envasado,
  GetEnvasadoQuery,
  ListEnvasadosQuery,
  ModelEnvasadoFilterInput,
  ModelSubscriptionEnvasadoFilterInput,
  ModelSubscriptionIDInput,
} from '../../app-sync.service';
import { appConstants } from '../../shared/constants/constants';
import { DocumentsService } from '../documents/documents.service';
import { Subject } from 'rxjs';
import { UsersService } from '../users/users.service';
import { filterInputs } from '../../shared/types/filter-inputs';

@Injectable({
  providedIn: 'root',
})
export class EnvasadosService {
  selectedEnvasadoChanged = new Subject<Envasado>();
  envasadosChanged = new Subject<Envasado[]>();

  private readonly initialEnvasado: Envasado;
  private selectedEnvasado: Envasado;
  private envasados: Envasado[] = [];
  private envasadosFilter: ModelEnvasadoFilterInput = {};
  private envasadosFilterForSubscriptions: ModelSubscriptionEnvasadoFilterInput =
    {};
  private selectedEnvasadoFilterForSubscriptions: ModelSubscriptionEnvasadoFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService
  ) {
    this.initialEnvasado = {
      ...appConstants.envasado.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedEnvasado = this.initialEnvasado;
    this.setEnvasadosFilter();
  }

  // ---------------------------------------
  // Métodos para el envasado rígido default
  // ---------------------------------------
  /**
   * Retorna una estructura de envasado rígido vacía para iniciar
   * un objeto de tipo Envasado.
   * @return {Envasado}
   */
  getInitialEnvasado(): Envasado {
    return { ...this.initialEnvasado };
  }

  // --------------------------------------------
  // Métodos para el envasado rígido seleccionado
  // --------------------------------------------
  /**
   * Configura el envasado rígido seleccionado para referencia de
   * todos los componentes.
   * @param {Envasado} envasado Envasado rígido seleccionado.
   */
  setSelectedEnvasado(envasado: Envasado): void {
    this.selectedEnvasado = envasado;
    this.setSelectedEnvasadoFilterForSubscriptions();
    this.selectedEnvasadoChanged.next({ ...this.selectedEnvasado });
    console.log('selectedEnvasado', this.selectedEnvasado);

    // Documentos
    this.documentsService.setId('envasadoId', this.selectedEnvasado.envasadoId);
    this.documentsService.setSelectedModel('ENVASADO');
    this.documentsService.setSelectedCenter(envasado.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del envasado rígido actualizados.'));
  }

  /**
   * Actualiza los datos del envasado rígido seleccionado.
   * @return {Promise}
   */
  async refreshSelectedEnvasado(): Promise<void> {
    const getEnvasadoResult: GetEnvasadoQuery = await this.api.GetEnvasado(
      this.selectedEnvasado.envasadoId
    );
    this.setSelectedEnvasado(<Envasado>getEnvasadoResult);
  }

  /**
   * Retorna el envasado rígido seleccionado.
   * @return {Envasado}
   */
  getSelectedEnvasado(): Envasado {
    return { ...this.selectedEnvasado };
  }

  // ----------------------------------
  // Métodos para la lista de Vehículos
  // ----------------------------------
  /**
   * Configura la lista de envasados rígidos para referencia de
   * todos los componentes.
   * @param {Envasado[]} envasados Lista de envasados rígidos.
   */
  setEnvasados(envasados: Envasado[]): void {
    this.envasados = envasados;
    this.setEnvasadosFilter();
    this.envasadosChanged.next(this.envasados.slice());
  }

  /**
   * Actualiza la lista de envasados rígidos.
   * @return {Promise}
   */
  async refreshEnvasados(): Promise<void> {
    let listEnvasadosResult: ListEnvasadosQuery = await this.api.ListEnvasados(
      '',
      this.getEnvasadosFilter()
    );
    let tempListEnvasados = <Envasado[]>listEnvasadosResult.items;
    let nextToken = listEnvasadosResult.nextToken;
    // Es posible que la primera query no retorne todos los envasados rígidos.
    while (nextToken) {
      let loopListEnvasadosResult: ListEnvasadosQuery =
        await this.api.ListEnvasados(
          '',
          this.getEnvasadosFilter(),
          100,
          nextToken
        );
      tempListEnvasados.push(...(<Envasado[]>loopListEnvasadosResult.items));
      nextToken = loopListEnvasadosResult.nextToken;
    }

    this.setEnvasados(tempListEnvasados.slice());
  }

  /**
   * Retorna la lista de envasados rígidos.
   * @return {Envasado[]}
   */
  getEnvasados(): Envasado[] {
    return this.envasados.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de envasados rígidos y subscripciones.
   * @private
   */
  private setEnvasadosFilter(): void {
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();

    this.envasadosFilter = <ModelEnvasadoFilterInput>modelFilterInput;
    this.envasadosFilterForSubscriptions = <
      ModelSubscriptionEnvasadoFilterInput
    >modelFilterInput;
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de envasados rígidos.
   * @return {ModelEnvasadoFilterInput}
   */
  getEnvasadosFilter(): ModelEnvasadoFilterInput {
    return { ...this.envasadosFilter };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de envasados rígidos.
   * @return {ModelSubscriptionEnvasadoFilterInput}
   */
  getEnvasadosFilterForSubscriptions(): ModelSubscriptionEnvasadoFilterInput {
    return { ...this.envasadosFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del envasado rígido seleccionado.
   */
  private setSelectedEnvasadoFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedEnvasado.envasadoId,
    };
    this.selectedEnvasadoFilterForSubscriptions = {
      envasadoId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del envasado rígido seleccionado.
   * @return {ModelSubscriptionEnvasadoFilterInput}
   */
  getSelectedEnvasadoFilterForSubscriptions(): ModelSubscriptionEnvasadoFilterInput {
    return { ...this.selectedEnvasadoFilterForSubscriptions };
  }
}
