import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { CognitoUser } from '../shared/interfaces/cognito-user';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  token: string = '';

  /**
   * Inicia sesión a través de Fusion Auth.
   * @return {Promise}
   */
  async federatedSignIn(): Promise<any> {
    await Auth.federatedSignIn({ customProvider: 'FusionAuth' });
    await Auth.verifyCurrentUserAttribute('email');
  }

  /**
   * Obtiene el usuario actualmente autenticado.
   */
  currentUser() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.token = user.signInUserSession.accessToken.jwtToken;
        datadogRum.setUser({
          id: user.username,
          name: user.attributes.name,
          email: user.attributes.email,
          role: user.attributes['custom:role'].split('"')[1],
          sub: user.attributes.sub,
        });
      })
      .catch((reason: any) =>
        console.log(`No hay un usuario autenticado: ${reason}`)
      );
  }

  /**
   * Cierra la sesión del usuario.
   * @return {Promise}
   */
  async signOut(): Promise<any> {
    await Auth.signOut({ global: true }).then((response: any): void => {
      localStorage.clear();
      console.log('SignOut response: ', response);
    });
  }

  /**
   * Obtiene la información del usuario actual.
   * @return {Promise}
   */
  getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  /**
   * Actualiza los atributos de un usuario en Cognito.
   * @param {CognitoUser} user Usuario Cognito.
   * @return {Promise}
   */
  async updateUser(user: CognitoUser): Promise<any> {
    const cognitoUser = await Auth.currentUserPoolUser();
    return Auth.updateUserAttributes(cognitoUser, user);
  }
}
