export const environment = {
  production: false,
  env: 'stg',
  version: 'stg-b87ce9f7',
  feedbackDelay: 5000,
  datadog: {
    app: 'c8ed6735-f5fb-46aa-986f-d7d079ec10c0',
    token: 'pubb47f2ac64ab09c0d9c5422f0272c0c5f',
    service: 'roz',
    enabled: true,
  },
  api: 'https://4wlvj8dgg1.execute-api.us-west-2.amazonaws.com',
};
