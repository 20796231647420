import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { appConstants } from '../../shared/constants/constants';

import {
  APIService,
  GetVehicleQuery,
  ListVehiclesQuery,
  ModelSubscriptionIDInput,
  ModelSubscriptionVehicleFilterInput,
  ModelVehicleFilterInput,
  Trailer,
  Vehicle,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { filterInputs } from '../../shared/types/filter-inputs';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService {
  selectedVehicleChanged = new Subject<Vehicle>();
  vehiclesChanged = new Subject<Vehicle[]>();
  vehicleHasTrailerChanged = new Subject<boolean>();
  vehicleHasTrailer: boolean = false;

  private readonly initialTrailer: Trailer;
  private readonly initialVehicle: Vehicle;
  private selectedTrailer: Trailer;
  private selectedVehicle: Vehicle;
  private selectedVehicleFilterForSubscriptions: ModelSubscriptionVehicleFilterInput =
    {};
  private vehicles: Vehicle[] = [];
  private vehiclesFilter: ModelVehicleFilterInput = {};
  private vehiclesFilterForSubscriptions: ModelSubscriptionVehicleFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService
  ) {
    this.initialVehicle = {
      ...appConstants.vehicle.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.initialTrailer = appConstants.trailer.initialization;
    this.selectedVehicle = this.initialVehicle;
    this.selectedTrailer = this.initialTrailer;
    this.setVehiclesFilter();
    this.setSelectedVehicleFilterForSubscriptions();
  }

  // ---------------------------------
  // Métodos para el vehículo default
  // ---------------------------------
  /**
   * Retorna una estructura de vehículo vacía para iniciar
   * un objeto de tipo Vehicle.
   * @return {Vehicle}
   */
  getInitialVehicle(): Vehicle {
    return { ...this.initialVehicle };
  }

  // -------------------------------------
  // Métodos para el vehículo seleccionado
  // -------------------------------------
  /**
   * Configura el vehículo seleccionado para referencia de
   * todos los componentes.
   * @param {Vehicle} vehicle Vehículo seleccionado.
   */
  setSelectedVehicle(vehicle: Vehicle): void {
    this.selectedVehicle = vehicle;
    this.setSelectedVehicleFilterForSubscriptions();
    this.selectedVehicleChanged.next({ ...this.selectedVehicle });
    console.log('selectedVehicle', this.selectedVehicle);

    // Documentos
    this.documentsService.setId('vehicleId', this.selectedVehicle.vehicleId);
    this.documentsService.setSelectedModel('VEHICLE');
    this.documentsService.setSelectedCenter(vehicle.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del vehículo actualizados.'));
  }

  /**
   * Actualiza los datos del vehículo seleccionado.
   * @return {Promise}
   */
  async refreshSelectedVehicle(): Promise<void> {
    const getVehicleResult: GetVehicleQuery = await this.api.GetVehicle(
      this.selectedVehicle.vehicleId
    );
    this.setSelectedVehicle(<Vehicle>getVehicleResult);
  }

  /**
   * Retorna el vehículo seleccionado.
   * @return {Vehicle}
   */
  getSelectedVehicle(): Vehicle {
    return { ...this.selectedVehicle };
  }

  // ------------------------------------------
  // Métodos para el semirremolque seleccionado
  // ------------------------------------------
  /**
   * Configura el semirremolque del vehículo seleccionado, si lo tiene,
   * para referencia de todos los componentes.
   * @param {Trailer} trailer Semirremolque
   */
  setSelectedTrailer(trailer: Trailer): void {
    this.selectedTrailer = trailer;

    // Documentos
    this.documentsService.setId('trailerId', this.selectedTrailer.trailerId);
    this.documentsService.setTrailerDocumentsFilter();
    this.documentsService
      .refreshTrailerDocuments()
      .then(() => console.log('Documentos del semirremolque actualizados.'));
  }

  /**
   * Libera la variable selectedTrailer en el caso de
   * que el vehículo seleccionado no tenga uno.
   */
  releaseSelectedTrailer(): void {
    this.selectedTrailer = this.initialTrailer;

    // Documentos
    this.documentsService.setId('trailerId', '');
    this.documentsService.cleanTrailerDocuments();
  }

  /**
   * Actualiza los datos del semirremolque seleccionado.
   * @param {string} trailerId ID del semirremolque.
   * @return {Promise}
   */
  async refreshSelectedTrailer(trailerId: string): Promise<void> {
    const getTrailerResult = await this.api.GetTrailer(trailerId);
    this.setSelectedTrailer(<Trailer>getTrailerResult);
  }

  /**
   * Retorna el semirremolque seleccionado.
   * @return {Trailer}
   */
  getSelectedTrailer(): Trailer {
    return { ...this.selectedTrailer };
  }

  /**
   * Configura las variables que indican que el vehículo
   * seleccionado tiene semirremolque.
   * @param {Boolean} value Responde a ¿tiene semirremolque?
   */
  setHasTrailer(value: boolean): void {
    this.vehicleHasTrailer = value;
    this.vehicleHasTrailerChanged.next(value);
  }

  // ----------------------------------
  // Métodos para la lista de Vehículos
  // ----------------------------------
  /**
   * Configura la lista de vehículos para referencia de
   * todos los componentes.
   * @param {Vehicle[]} vehicles Lista de vehículos.
   */
  setVehicles(vehicles: Vehicle[]): void {
    this.vehicles = vehicles;
    this.setVehiclesFilter();
    this.vehiclesChanged.next(this.vehicles.slice());
  }

  /**
   * Actualiza la lista de vehículos.
   * @return {Promise}
   */
  async refreshVehicles(): Promise<void> {
    let listVehiclesResult: ListVehiclesQuery = await this.api.ListVehicles(
      '',
      this.getVehiclesFilter()
    );
    let tempListVehicles = <Vehicle[]>listVehiclesResult.items;
    let nextToken = listVehiclesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListVehiclesResult: ListVehiclesQuery =
        await this.api.ListVehicles(
          '',
          this.getVehiclesFilter(),
          100,
          nextToken
        );
      tempListVehicles.push(...(<Vehicle[]>loopListVehiclesResult.items));
      nextToken = loopListVehiclesResult.nextToken;
    }

    this.setVehicles(tempListVehicles.slice());
  }

  /**
   * Retorna la lista de vehículos.
   * @return {Vehicle[]}
   */
  getVehicles(): Vehicle[] {
    return this.vehicles.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de vehículos y subscripciones.
   * @private
   */
  private setVehiclesFilter(): void {
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();

    this.vehiclesFilter = <ModelVehicleFilterInput>modelFilterInput;
    this.vehiclesFilterForSubscriptions = <ModelSubscriptionVehicleFilterInput>(
      modelFilterInput
    );
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de vehículos.
   * @return {ModelVehicleFilterInput}
   */
  getVehiclesFilter(): ModelVehicleFilterInput {
    return { ...this.vehiclesFilter };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de vehículos.
   * @return {ModelSubscriptionVehicleFilterInput}
   */
  getVehiclesFilterForSubscriptions(): ModelSubscriptionVehicleFilterInput {
    return { ...this.vehiclesFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del vehículo seleccionado.
   */
  private setSelectedVehicleFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedVehicle.vehicleId,
    };
    this.selectedVehicleFilterForSubscriptions = {
      vehicleId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del vehículo seleccionado.
   * @return {ModelSubscriptionVehicleFilterInput}
   */
  getSelectedVehicleFilterForSubscriptions(): ModelSubscriptionVehicleFilterInput {
    return { ...this.selectedVehicleFilterForSubscriptions };
  }
}
