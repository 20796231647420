import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OAuthService, OAuthModule } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Amplify, I18n } from 'aws-amplify';
import { AuthenticatorService, translations } from '@aws-amplify/ui-angular';
import { NgOptimizedImage } from '@angular/common';

import { CisternasModule } from './pages/cisternas/cisternas.module';
import { DocumentsModule } from './pages/documents/documents.module';
import { DriversModule } from './pages/drivers/drivers.module';
import { CouplingsModule } from './pages/couplings/couplings.module';
import { EnvasadosModule } from './pages/envasados/envasados.module';
import { ErrorPagesModule } from './error-pages/error-pages.module';
import { MasterModule } from './pages/master/master.module';
import { SemirremolquesModule } from './pages/semirremolques/semirremolques.module';
import { SharedModule } from './shared/shared.module';
import { TanquesModule } from './pages/tanques/tanques.module';
import { TractosModule } from './pages/tractos/tractos.module';
import { UsersModule } from './pages/users/users.module';
import { VehiclesModule } from './pages/vehicles/vehicles.module';
import { RtcEvaluationsModule } from './pages/rtc-evaluations/rtc-evaluations.module';

import { AppComponent } from './app.component';
import { BusinessSelectorComponent } from './home/business-selector/business-selector.component';
import { FeedbacksComponent } from './shared/feedbacks/feedbacks.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { LoggingInterceptor } from './interceptor/auth_interceptor';

import awsconfig from '../aws-exports';
import { HistorialRtcModule } from './pages/historial-rtc/historial-rtc.module';

Amplify.configure(awsconfig);

I18n.putVocabularies(translations);
I18n.setLanguage('es');
I18n.putVocabularies({
  es: {
    'Enter your Email': 'Ingrese su dirección de correo electrónico',
    'Enter your Password': 'Ingrese su contraseña',
    'Reset Password': 'Resetear contraseña',
  },
});

@NgModule({
  declarations: [
    AppComponent,
    BusinessSelectorComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    FooterComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CisternasModule,
    CouplingsModule,
    DocumentsModule,
    DriversModule,
    EnvasadosModule,
    ErrorPagesModule,
    FeedbacksComponent,
    FormsModule,
    HttpClientModule,
    MasterModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    SemirremolquesModule,
    SharedModule,
    TanquesModule,
    TractosModule,
    UsersModule,
    VehiclesModule,
    RtcEvaluationsModule,
    HistorialRtcModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    AuthenticatorService,
    OAuthService,
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
