<div class="container">
  <div class="text-center my-5" *ngIf="isLoading">
    <app-spinner message="Cargando"></app-spinner>
  </div>
  <div class="col-sm-12" *ngIf="!isLoading">
    <form [formGroup]="evaluationForm">
      <div class="row mb-3">
        <div class="col-sm-5">
          <h4 class="float-start">Evaluación RTC</h4>
        </div>
        <div class="col-sm-2 divCenter">
          <h6 *ngIf="showFormControls && rozFormExists">
            <span
              class="badge"
              [ngStyle]="{ 'background-color': getColor() }"
              style="width: 120px; border-radius: 60px"
            >
              <span>
                <img
                  [src]="getImageSrc()"
                  height="20"
                  width="20"
                  alt="Estado"
                />
              </span>
              {{ currentRozStatus }}</span
            >
          </h6>
        </div>
        <div class="col-sm-5">
          <div *ngIf="showFormControls">
            <button
              id="evaluation-submit"
              type="button"
              class="btn btn-primary mx-2 float-end"
              (click)="
                onSubmitEvaluation('send', evaluationForm.get('comment')?.value)
              "
              [disabled]="!evaluationForm.valid"
            >
              Enviar
            </button>
            <button
              id="evaluation-save"
              type="button"
              class="btn btn-outline-primary mx-2 float-end"
              (click)="
                onSubmitEvaluation('save', evaluationForm.get('comment')?.value)
              "
            >
              Guardar
            </button>
            <label
              class="custom-file-upload pt-2 mx-1 float-end"
              style="cursor: pointer"
            >
              <a
                id="general-comment"
                title="Comentario"
                (click)="
                  onAddGeneralComment(evaluationForm.get('comment')?.value)
                "
              >
                <span
                  class="material-symbols-outlined"
                  [ngClass]="
                    getCommentClass(evaluationForm.get('comment')?.value)
                  "
                  >comment</span
                >
              </a>
            </label>
            <button
              id="evaluation-show"
              type="button"
              class="btn btn-outline-secondary mx-2 float-end"
              (click)="showForm()"
              *ngIf="isDev"
            >
              Log Form
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="col-xs-12" formArrayName="questions">
          <div
            class="row border rounded-2 p-2 my-1"
            id="question-{{ i }}"
            *ngFor="let questionControl of questionsControl; let i = index"
            [formGroupName]="i"
          >
            <div class="row">
              <p>
                {{ questionControl.get("order")?.value }}.
                {{ questionControl.get("question")?.value }}
              </p>
            </div>
            <div class="row">
              <div class="col-sm-1 text-center border rounded mx-1">
                <div class="form-check form-check-inline pt-2">
                  <input
                    type="radio"
                    id="yesAnswer-{{ i }}"
                    formControlName="answer"
                    class="form-check-input"
                    value="Si"
                    (change)="updateScore()"
                  />
                  <label for="yesAnswer-{{ i }}" class="form-check-label"
                    >Sí</label
                  >
                </div>
              </div>
              <div class="col-sm-1 text-center border rounded mx-1">
                <div class="form-check form-check-inline pt-2">
                  <input
                    type="radio"
                    id="noAnswer-{{ i }}"
                    formControlName="answer"
                    class="form-check-input"
                    value="No"
                    (change)="updateScore()"
                  />
                  <label for="noAnswer-{{ i }}" class="form-check-label"
                    >No</label
                  >
                </div>
              </div>
              <div class="col-sm text-end text-secondary">
                <label
                  class="custom-file-upload pt-2 mx-1"
                  style="cursor: pointer"
                >
                  <a
                    id="comment"
                    title="Comentario"
                    (click)="
                      onAddComment(
                        questionControl.get('comment')?.value,
                        questionControl.get('question')?.value,
                        i
                      )
                    "
                  >
                    <span
                      class="material-symbols-outlined"
                      [ngClass]="
                        getCommentClass(questionControl.get('comment')?.value)
                      "
                      >comment</span
                    >
                  </a>
                </label>
                <label
                  class="custom-file-upload pt-2 mx-1"
                  style="cursor: pointer"
                  *ngIf="
                    !(
                      questionHasS3Image(
                        questionControl.get('imageS3Path')?.value
                      ) ||
                      questionHasLocalImage(
                        questionControl.get('imageLocalPath')?.value
                      )
                    )
                  "
                >
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    id="image-file-{{ i }}"
                    formControlName="imageFile"
                    class="form-control"
                    (change)="onChangeImageFile($event, i)"
                  />
                  <span
                    title="Agregar Imagen"
                    class="material-symbols-outlined"
                  >
                    photo_camera
                  </span>
                </label>
                <label
                  class="custom-file-upload pt-2 mx-1"
                  style="cursor: pointer"
                  *ngIf="
                    questionHasS3Image(
                      questionControl.get('imageS3Path')?.value
                    ) ||
                    questionHasLocalImage(
                      questionControl.get('imageLocalPath')?.value
                    )
                  "
                >
                  <a
                    id="view-image"
                    title="Ver Imagen"
                    (click)="onViewImage(i)"
                  >
                    <span class="material-symbols-outlined with-content"
                      >image</span
                    >
                  </a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Modal enviar/guardar evaluación-->
<ng-template #rtcSubmitModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-save-send-evaluation">
      {{ modalTitle }}
    </h4>
    <button
      id="modal-save-send-evaluation-close"
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      <strong>{{ modalQuestion }}</strong>
    </p>
    <p>{{ modalMessage }}</p>

    <div class="divCenter">
      <div class="col-sm-8">
        <table class="table table-bordered text-center" style="color: black">
          <caption>
            Resumen de la Evaluación
          </caption>
          <tbody style="color: black">
            <tr>
              <th scope="row">Preguntas con Observación</th>
              <td>{{ totalComments }}</td>
            </tr>
            <tr>
              <th scope="row">Preguntas con Imágenes</th>
              <td>{{ totalImages }}</td>
            </tr>
          </tbody>
        </table>
        <form [formGroup]="commentForm">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm">
                <label for="comment" class="form-label">Observación</label>
                <div class="form-group">
                  <input
                    type="text"
                    id="comment-3"
                    formControlName="comment"
                    class="form-control immutable-field"
                    placeholder="Observación..."
                    appInputTrim
                  />
                  <div *ngIf="showHelper(commentControl.get('comment'))">
                    <small class="form-text text-muted">
                      {{ helperMessages(commentControl.get("comment")) }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      id="modal-save-send-evaluation-cancel"
      type="button"
      class="btn btn-outline-danger"
      (click)="d('Cancel')"
    >
      Cancelar
    </button>
    <button
      id="modal-save-send-evaluation-accept"
      type="button"
      class="btn btn-outline-primary"
      (click)="c('Accept')"
    >
      Aceptar
    </button>
  </div>
</ng-template>

<!--Modal para visualizar imágenes-->
<ng-template #documentViewerModal let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-view-image">{{ modalTitle }}</h4>
        <button
          id="document-item-modal-cross"
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="d('Cross')"
        ></button>
      </div>
      <div class="modal-body">
        <div class="text-center my-5" *ngIf="isGettingURL">
          <app-spinner message="Generando Vista Previa"></app-spinner>
        </div>
        <img
          [src]="imageSource"
          (load)="imageLoaded()"
          style="max-width: 100%; max-height: 70vh"
          alt="Imagen de la pregunta"
        />
      </div>
      <div class="modal-footer" *ngIf="!isGettingURL">
        <button
          id="modal-save-send-evaluation-delete"
          type="button"
          class="btn btn-outline-danger"
          (click)="d('Delete')"
        >
          Borrar
        </button>
        <button
          id="modal-view-image-close"
          type="button"
          class="btn btn-outline-primary"
          (click)="c('Accept')"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!--Modal para ingresar comentarios-->
<ng-template #commentModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
    <button
      id="cisterna-details-modal-close"
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      <strong>{{ modalQuestion }}</strong>
    </p>
    <p>{{ modalMessage }}</p>
    <form [formGroup]="commentForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm">
            <label for="comment" class="form-label">Observación</label>
            <div class="form-group">
              <input
                type="text"
                id="comment-2"
                formControlName="comment"
                class="form-control immutable-field"
                placeholder="Observación..."
                appInputTrim
              />
              <div *ngIf="showHelper(commentControl.get('comment'))">
                <small class="form-text text-muted">
                  {{ helperMessages(commentControl.get("comment")) }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      id="cisterna-details-modal-cancel"
      type="button"
      class="btn btn-outline-danger"
      (click)="d('Cancel')"
    >
      Cancelar
    </button>
    <button
      id="cisterna-details-modal-accept"
      type="button"
      class="btn btn-outline-primary"
      (click)="c('Accept')"
      [disabled]="!commentForm.valid"
    >
      Aceptar
    </button>
  </div>
</ng-template>

<!-- Modal borrar imagen-->
<ng-template #deleteImageModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-delete-image">
      {{ modalTitle }}
    </h4>
    <button
      id="modal-delete-image-close"
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      <strong>{{ modalQuestion }}</strong>
    </p>
    <p>{{ modalMessage }}</p>
  </div>
  <div class="modal-footer">
    <button
      id="modal-delete-image-cancel"
      type="button"
      class="btn btn-outline-danger"
      (click)="d('Cancel')"
    >
      Cancelar
    </button>
    <button
      id="modal-delete-image-accept"
      type="button"
      class="btn btn-outline-primary"
      (click)="c('Accept')"
    >
      Aceptar
    </button>
  </div>
</ng-template>

<app-scroll-to-top></app-scroll-to-top>
