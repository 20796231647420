import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  APIService,
  Cisterna,
  GetCisternaQuery,
  ListCisternasQuery,
  ModelCisternaFilterInput,
  ModelSubscriptionCisternaFilterInput,
  ModelSubscriptionIDInput,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { appConstants } from '../../shared/constants/constants';
import { filterInputs } from '../../shared/types/filter-inputs';

@Injectable({
  providedIn: 'root',
})
export class CisternasService {
  selectedCisternaChanged = new Subject<Cisterna>();
  cisternasChanged = new Subject<Cisterna[]>();

  private readonly initialCisterna: Cisterna;
  private selectedCisterna: Cisterna;
  private cisternas: Cisterna[] = [];
  private cisternasFilter: ModelCisternaFilterInput = {};
  private cisternasFilterForSubscriptions: ModelSubscriptionCisternaFilterInput =
    {};
  private selectedCisternaFilterForSubscriptions: ModelSubscriptionCisternaFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService
  ) {
    this.initialCisterna = {
      ...appConstants.cisterna.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedCisterna = this.initialCisterna;
    this.setCisternasFilter();
    this.setSelectedCisternaFilterForSubscriptions();
  }

  // --------------------------------
  // Métodos para la cisterna default
  // --------------------------------
  /**
   * Retorna una estructura de cisterna vacía para iniciar
   * un objeto de tipo Cisterna.
   * @return {Cisterna}
   */
  getInitialCisterna(): Cisterna {
    return { ...this.initialCisterna };
  }

  // -------------------------------------
  // Métodos para la cisterna seleccionada
  // -------------------------------------
  /**
   * Configura la cisterna seleccionada para referencia de
   * todos los componentes.
   * @param {Cisterna} cisterna Cisterna seleccionada.
   */
  setSelectedCisterna(cisterna: Cisterna): void {
    this.selectedCisterna = cisterna;
    this.setSelectedCisternaFilterForSubscriptions();
    this.selectedCisternaChanged.next({ ...this.selectedCisterna });
    console.log('selectedCisterna', this.selectedCisterna);

    // Documentos
    this.documentsService.setId('cisternaId', this.selectedCisterna.cisternaId);
    this.documentsService.setSelectedModel('CISTERNA');
    this.documentsService.setSelectedCenter(cisterna.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos de la cisterna actualizados.'));
  }

  /**
   * Actualiza los datos de la cisterna seleccionada.
   * @return {Promise}
   */
  async refreshSelectedCisterna(): Promise<void> {
    const getCisternaResult: GetCisternaQuery = await this.api.GetCisterna(
      this.selectedCisterna.cisternaId
    );
    this.setSelectedCisterna(<Cisterna>getCisternaResult);
  }

  /**
   * Retorna la cisterna seleccionada.
   * @return {Cisterna}
   */
  getSelectedCisterna(): Cisterna {
    return { ...this.selectedCisterna };
  }

  // ----------------------------------
  // Métodos para la lista de Vehículos
  // ----------------------------------
  /**
   * Configura la lista de cisternas para referencia de
   * todos los componentes.
   * @param {Cisterna[]} cisternas Lista de cisternas.
   */
  setCisternas(cisternas: Cisterna[]): void {
    this.cisternas = cisternas;
    this.setCisternasFilter();
    this.cisternasChanged.next(this.cisternas.slice());
  }

  /**
   * Actualiza la lista de cisternas.
   * @return {Promise}
   */
  async refreshCisternas(): Promise<void> {
    let listCisternasResult: ListCisternasQuery = await this.api.ListCisternas(
      '',
      this.getCisternasFilter()
    );
    let tempListCisternas = <Cisterna[]>listCisternasResult.items;
    let nextToken = listCisternasResult.nextToken;
    // Es posible que la primera query no retorne todas las cisternas.
    while (nextToken) {
      let loopListCisternasResult: ListCisternasQuery =
        await this.api.ListCisternas(
          '',
          this.getCisternasFilter(),
          100,
          nextToken
        );
      tempListCisternas.push(...(<Cisterna[]>loopListCisternasResult.items));
      nextToken = loopListCisternasResult.nextToken;
    }

    this.setCisternas(tempListCisternas.slice());
  }

  /**
   * Retorna la lista de cisternas.
   * @return {Cisterna[]}
   */
  getCisternas(): Cisterna[] {
    return this.cisternas.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de cisternas y subscripciones.
   * @private
   */
  private setCisternasFilter(): void {
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();

    this.cisternasFilter = <ModelCisternaFilterInput>modelFilterInput;
    this.cisternasFilterForSubscriptions = <
      ModelSubscriptionCisternaFilterInput
    >modelFilterInput;
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de cisternas.
   * @return {ModelCisternaFilterInput}
   */
  getCisternasFilter(): ModelCisternaFilterInput {
    return { ...this.cisternasFilter };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de cisternas.
   * @return {ModelSubscriptionCisternaFilterInput}
   */
  getCisternasFilterForSubscriptions(): ModelSubscriptionCisternaFilterInput {
    return { ...this.cisternasFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * de la cisterna seleccionada.
   */
  private setSelectedCisternaFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedCisterna.cisternaId,
    };
    this.selectedCisternaFilterForSubscriptions = {
      cisternaId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de la cisterna seleccionada.
   * @return {ModelSubscriptionCisternaFilterInput}
   */
  getSelectedCisternaFilterForSubscriptions(): ModelSubscriptionCisternaFilterInput {
    return { ...this.selectedCisternaFilterForSubscriptions };
  }
}
